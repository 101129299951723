import config from "../config";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Source: "bo",
};

export const post = async (route, object) => {
  const token = localStorage.getItem("tokenModeration");
  console.log("ICIPost " + config.environment.apiUrl + route);
  const headers = { ...defaultHeaders };
  if (token) {
    headers["Authorization"] = token;
  }
  const response = await fetch(config.environment.apiUrl + route, {
    method: "post",
    headers,
    body: JSON.stringify(object),
  }).then((res) => res.json());
  return response.status === "success"
    ? response.data
    : { error: response.message };
};

export const get = async (route) => {
  const token = localStorage.getItem("tokenModeration");
  const headers = { ...defaultHeaders };
  if (token) {
    headers["Authorization"] = token;
  }
  const response = await fetch(config.environment.apiUrl + route, {
    method: "get",
    headers,
  }).then((res) => res.json());
  return response.status === "success"
    ? response.data
    : { error: response.message };
};

export const del = async (route) => {
  const token = localStorage.getItem("tokenModeration");
  const headers = { ...defaultHeaders };
  if (token) {
    headers["Authorization"] = token;
  }
  const response = await fetch(config.environment.apiUrl + route, {
    method: "delete",
    headers,
  }).then((res) => res.json());
  return response.status === "success"
    ? response.data
    : { error: response.message };
};

export const put = async (route, object) => {
  const token = localStorage.getItem("tokenModeration");
  const headers = { ...defaultHeaders };
  if (token) {
    headers["Authorization"] = token;
  }
  const response = await fetch(config.environment.apiUrl + route, {
    method: "put",
    headers,
    body: JSON.stringify(object),
  }).then((res) => res.json());
  return response.status === "success"
    ? response.data
    : { error: response.message };
};

export const getS3Url = async (type) => {
  const res = await get(`/s3/bucket/url?type=${type}`);
  return res;
};

export const uploadFile = async (file) => {
  let type = file.type;
  const { url } = await getS3Url(type);
  const headersContent = {
    "Content-Type": file.type,
    Accept: "application/json",
  };
  const headers = new Headers(headersContent);
  const response = await fetch(url, {
    method: "PUT",
    body: file,
    headers,
  });

  if (!response) return null;
  let resUrl = new URL(response.url);

  return resUrl.protocol + "//" + resUrl.host + resUrl.pathname;
};
