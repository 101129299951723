import React, { useState, useEffect } from "react";
import PlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete";

export default function GoogleAutocomplete({
  placeholder,
  setAddressData,
  initialAddress,
}) {
  const [address, setAddress] = useState("");
  const handleChange = (address) => {
    setAddress(address);
  };
  const handleSelect = (selected) => {
    setAddress(selected);
    geocodeByAddress(selected)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setAddressData({ ...latLng, address: selected }))
      .catch((error) =>
        console.error("[Google Places Autocomplete] error", error)
      );
  };

  useEffect(() => {
    if (!!initialAddress) {
      setAddress(initialAddress);
    }
  }, [initialAddress]); // eslint-disable-line

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        types: ["geocode"],
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: "relative" }}>
          <input
            {...getInputProps({
              placeholder: placeholder,
              className: "location-search-input",
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: "#474747",
                    cursor: "pointer",
                  }
                : {
                    //backgroundColor: "#ffffff",
                    cursor: "pointer",
                  };
              return (
                <div
                  key={suggestion.description}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
