import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Grid, Row as BootstrapRow, Col, Alert, Button } from "react-bootstrap";
import Loader from "react-loader-spinner";

import Card from "components/Card/Card.jsx";

import { getLiveStreams, adminStopLive } from "services/live.service";

let timeout = null;

export default function LiveScreen() {
  const [liveStreams, setLiveStreams] = useState([]);
  const [status, setStatus] = useState("loading"); // ENUM (idle | loading)

  const forceStopLive = (streamName) => {
    adminStopLive(streamName).then((res) => {
      if (res?.success) {
        setLiveStreams((prevLiveStreams) =>
          prevLiveStreams.filter((live) => live.stream_name !== streamName)
        );
      }
    });
  };

  useEffect(() => {
    getLiveStreams().then((res) => {
      timeout = setTimeout(() => {
        setStatus("idle");
        if (Array.isArray(res)) {
          setLiveStreams(res);
        }
      }, 500);
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="main-content">
      <Grid fluid>
        <BootstrapRow>
          <Col md={12}>
            <Card
              title="All live streams"
              content={
                <div>
                  {liveStreams.length > 0 ? (
                    <table style={{ width: 600 }}>
                      <thead>
                        <tr>
                          <HeaderCell>Title</HeaderCell>
                          <HeaderCell>User</HeaderCell>
                          <HeaderCell></HeaderCell>
                        </tr>
                      </thead>
                      <tbody>
                        {liveStreams.map((live) => (
                          <Row key={live.id}>
                            <Cell>{live.title}</Cell>
                            <Cell>{live.user.pseudo}</Cell>
                            <Cell>
                              <Button
                                className="btn-filled btn-danger"
                                onClick={() => {
                                  forceStopLive(live.stream_name);
                                }}
                              >
                                Stop live
                              </Button>
                            </Cell>
                          </Row>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <>
                      {status === "loading" && <Loader type="TailSpin" />}
                      <CustomAlert
                        className="alert-secondary"
                        opacity={status === "loading" ? 0 : 1}
                      >
                        There are currently zero live streams.
                      </CustomAlert>
                    </>
                  )}
                </div>
              }
            />
          </Col>
        </BootstrapRow>
      </Grid>
    </div>
  );
}

const Row = styled.tr`
  user-select: none;
  cursor: pointer;
  transition: all 200ms ease;
  height: 58px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const HeaderCell = styled.th`
  padding: 10px 5px;
`;

const Cell = styled.td`
  padding: 10px 5px;
`;

export const CustomAlert = styled(Alert)`
  opacity: ${(p) => p.opacity || "0"};
  transition: opacity 400ms ease;
`;
