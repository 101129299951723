import React, { useState } from "react";
import styled from "styled-components/macro";
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import VatConfiguration from "./components/vat-configuration.component";
import DisplayAccounting from "./components/display-accounting.component";

const mapModeToTitle = {
  display: "Accounting statistics",
  vat_configuration: "VAT configuration",
};

const mapModeToComponent = {
  display: <DisplayAccounting />,
  vat_configuration: <VatConfiguration />,
};

export default function AccountingScreen() {
  const [mode, setMode] = useState("display"); // "display" | "vat_configuration"
  return (
    <div className="main-content">
      <ModeSelector>
        <Mode active={mode === "display"} onClick={() => setMode("display")}>
          Statistics
        </Mode>
        <Mode
          active={mode === "vat_configuration"}
          onClick={() => setMode("vat_configuration")}
        >
          VAT configuration for EU-countries
        </Mode>
      </ModeSelector>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={mapModeToTitle[mode]}
              content={mapModeToComponent[mode]}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

const ModeSelector = styled.div`
  margin-bottom: 12px;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const Mode = styled.div`
  transition: all 200ms ease;
  cursor: pointer;
  user-select: none;
  padding: 8px 24px;
  margin-right: 8px;
  font-size: 14px;
  border-bottom: 2px solid
    ${(props) => (props.active ? "#cecece" : "transparent")};

  &:hover {
    background-color: #cecece;
  }
`;
