import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Loader from "react-loader-spinner";
import {
  Alert,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";
import Select from "../../../components/Form/select.input";
import Textarea from "../../../components/Form/textarea.input";
import GoogleAutocomplete from "../../../components/Form/google-autocomplete.input";

import { uploadFile } from "../../../services/utils.service";
import { trimObject } from "../../../utils/global.utils";

export default function SaveGuideForm({
  saveGuideHandler,
  mode = "create", // ENUM ("create" | "update")
  initialData = null, // seulement utile pour le mode "update"
  status,
  errorMessage,
}) {
  const [uploadStatus, setUploadStatus] = useState("idle"); // "idle" | "loading"
  const [availableTimezones, setAvailableTimezones] = useState([]);

  const [type, setType] = useState("event");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [city, setCity] = useState("");
  const [venue, setVenue] = useState("");
  const [address, setAddress] = useState("");
  const [timeZone, setTimeZone] = useState("GTM+0");
  const [startDatetime, setStartDatetime] = useState("");
  const [endDatetime, setEndDatetime] = useState("");
  const [website, setWebsite] = useState("");
  const [ticket, setTicket] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [email, setEmail] = useState("");
  const [picture, setPicture] = useState({ path: null });
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const hasDuration =
    type === "event" || type === "accommodation" || type === "art_culture";

  const submitHandler = () => {
    if (uploadStatus === "loading") {
      return;
    }

    const body = {
      type,
      title,
      description,
      city,
      venue,
      address,
      timeZone,
      website,
      ticket,
      organizer,
      email,
      picture,
      latitude,
      longitude,
      startDatetime,
      endDatetime,
    };
    trimObject(body);

    saveGuideHandler(body);
  };

  const uploadPictureHandler = (files) => {
    if (uploadStatus === "loading") {
      return;
    }

    const [picture] = files;
    setUploadStatus("loading");
    uploadFile(picture).then((res) => {
      setUploadStatus("idle");
      if (!!res && typeof res === "string") {
        setPicture({ path: res });
      }
    });
  };

  const setAddressData = (data) => {
    setAddress(data.address);
    setLatitude(data.lat);
    setLongitude(data.lng);
  };

  useEffect(() => {
    if (mode === "update") {
      setType(initialData.type);
      setTitle(initialData.title);
      setDescription(initialData.description);
      setCity(initialData.city);
      setVenue(initialData.venue);
      setAddress(initialData.address);
      setTimeZone(initialData.time_zone);
      setStartDatetime(initialData.start_datetime);
      setEndDatetime(initialData.end_datetime);
      setWebsite(initialData.website);
      setTicket(initialData.ticket);
      setOrganizer(initialData.organizer);
      setEmail(initialData.email);
      setLatitude(initialData.latitude);
      setLongitude(initialData.longitude);
    }
  }, [mode]); // eslint-disable-line

  // Détermine les timezones disponibles pour un guide
  useEffect(() => {
    let timezones = [
      {
        label: `GTM+0`,
        value: `GTM+0`,
      },
    ];
    for (let i = 1; i <= 12; i++) {
      timezones.push({
        label: `GTM+${i}`,
        value: `GTM+${i}`,
      });
      timezones.push({
        label: `GTM-${i}`,
        value: `GTM-${i}`,
      });
    }
    setAvailableTimezones(timezones);
  }, []);

  return (
    <Form horizontal>
      {mode === "create" && (
        <fieldset>
          <FormGroup>
            <ControlLabel className="col-sm-2">Type</ControlLabel>
            <Col sm={10}>
              <Select value={type} onChange={(e) => setType(e.target.value)}>
                <option value="event">Event</option>
                <option value="bar_night_club">Bar / Night club</option>
                <option value="restaurant">Restaurant</option>
                <option value="shop">Shop</option>
                <option value="association">Association</option>
                <option value="accommodation">Accommodation</option>
                <option value="art_culture">Art / Culture</option>
              </Select>
            </Col>
          </FormGroup>
        </fieldset>
      )}
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Title</ControlLabel>
          <Col sm={10}>
            <FormControl
              type="text"
              placeholder="Title"
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Description</ControlLabel>
          <Col sm={10}>
            <Textarea
              placeholder="Description"
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              rows={3}
            />
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">City</ControlLabel>
          <Col sm={10}>
            <FormControl
              placeholder="City"
              type="text"
              name="city"
              onChange={(e) => setCity(e.target.value)}
              value={city}
            />
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Venue</ControlLabel>
          <Col sm={10}>
            <FormControl
              placeholder="Venue"
              type="text"
              name="venue"
              onChange={(e) => setVenue(e.target.value)}
              value={venue}
            />
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Full address</ControlLabel>
          <Col sm={10}>
            <GoogleAutocomplete
              placeholder="Full address"
              initialAddress={initialData?.address}
              setAddressData={setAddressData}
            />
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Time zone</ControlLabel>
          <Col sm={10}>
            <Select
              value={timeZone}
              onChange={(e) => setTimeZone(e.target.value)}
            >
              {availableTimezones.map((timezone, index) => (
                <option key={timezone.value} value={timezone.value}>
                  {timezone.label}
                </option>
              ))}
            </Select>
          </Col>
        </FormGroup>
      </fieldset>
      {hasDuration && (
        <>
          <fieldset>
            <FormGroup>
              <ControlLabel className="col-sm-2">
                Start date & time
              </ControlLabel>
              <Col sm={10}>
                <MaxWidth>
                  <FormControl
                    placeholder="Start date"
                    type="datetime-local"
                    name="start-datetime"
                    onChange={(e) => setStartDatetime(e.target.value)}
                    value={startDatetime}
                  />
                </MaxWidth>
              </Col>
            </FormGroup>
          </fieldset>
          <fieldset>
            <FormGroup>
              <ControlLabel className="col-sm-2">End date & time</ControlLabel>
              <Col sm={10}>
                <MaxWidth>
                  <FormControl
                    placeholder="End date"
                    type="datetime-local"
                    name="end-datetime"
                    onChange={(e) => setEndDatetime(e.target.value)}
                    value={endDatetime}
                  />
                </MaxWidth>
              </Col>
            </FormGroup>
          </fieldset>
        </>
      )}
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Website</ControlLabel>
          <Col sm={10}>
            <FormControl
              placeholder="Website"
              type="text"
              name="website"
              onChange={(e) => setWebsite(e.target.value)}
              value={website}
            />
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Ticket website</ControlLabel>
          <Col sm={10}>
            <FormControl
              placeholder="Ticket website"
              type="text"
              name="ticket-website"
              onChange={(e) => setTicket(e.target.value)}
              value={ticket}
            />
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">
            {mode === "create" ? "Picture" : "New picture"}
          </ControlLabel>
          <Col sm={10}>
            <FormControl
              type="file"
              name="picture"
              onChange={(e) => uploadPictureHandler(e.target.files)}
            />
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Organizer</ControlLabel>
          <Col sm={10}>
            <FormControl
              placeholder="Organizer"
              type="text"
              name="organizer"
              onChange={(e) => setOrganizer(e.target.value)}
              value={organizer}
            />
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Email</ControlLabel>
          <Col sm={10}>
            <FormControl
              placeholder="Email"
              type="text"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Col>
        </FormGroup>
      </fieldset>
      {status === "error" && (
        <fieldset>
          <Alert variant="danger" className="alert-danger">
            {errorMessage}
          </Alert>
        </fieldset>
      )}
      <fieldset>
        {status === "loading" ? (
          <Loader
            type="Circles"
            color="#9e9e9e"
            height={25}
            width={25}
            style={{ float: "right" }}
          />
        ) : (
          status !== "success" && (
            <Button style={{ float: "right" }} onClick={submitHandler}>
              {mode === "create" ? "Submit" : "Update"}
            </Button>
          )
        )}
      </fieldset>
    </Form>
  );
}

const MaxWidth = styled.div`
  max-width: 250px;
`;
