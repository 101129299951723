/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Row,
} from "react-bootstrap";

import styled from "styled-components/macro";
import TextareaInput from "components/Form/textarea.input";
import { getVariableById } from "services/variables.service";
import { updateVariableById } from "services/variables.service";

class VariableDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      onUpdate: props.onUpdate,
      variable: undefined,
      redirect: false,
    };
  }
  componentDidMount() {
    this.fetchVariable();
  }

  fetchVariable = async () => {
    const json = await getVariableById(this.state.id);
    this.setState({ ...this.state, variable: json });
  };
  updateVariable = async (data) => {
    await updateVariableById(this.state.id, data);
  };
  onSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const json = JSON.stringify();
    await this.updateVariable(Object.fromEntries(data));
    this.state.onUpdate();
  };

  render() {
    return (
      <div className="main-content">
        {this.state.variable && (
          <Grid fluid>
            <Row>
              <form onSubmit={this.onSubmit}>
                <FormGroup>
                  <ControlLabel>Key</ControlLabel>
                  <FormControl
                    disabled={true}
                    type="text"
                    defaultValue={this.state.variable.key_name}
                    name="key_name"
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Value</ControlLabel>

                  <FormControl
                    type="text"
                    defaultValue={this.state.variable.value}
                    name="value"
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Description</ControlLabel>

                  <TextareaInput
                    defaultValue={this.state.variable.description}
                    name="description"
                  />
                </FormGroup>
                <Button bsStyle="primary" type="submit">
                  Editer
                </Button>
              </form>
            </Row>
          </Grid>
        )}
      </div>
    );
  }
}

export default VariableDetail;
