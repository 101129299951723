import Dashboard from "views/Dashboard.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import PicturesPage from "views/Pictures/Pictures.jsx";
import VideoGrid from "views/Videos/VideoGrid.jsx";
import UsersPage from "views/Users/Users.jsx";
import HistoryPage from "views/History/History";
import GuysPage from "views/Guys/guys.screen";
import GuidesPage from "views/Guides/guides.screen";
import SanctionsPage from "views/Sanctions/sanctions.screen";
import ReportsPage from "views/Reports/reports.screen";
import LivePage from "views/Lives/live.screen";
import SubscriptionsPage from "views/Subscriptions/subscriptions.screen";
import AccountingPage from "views/Accouting/accounting.screen.jsx";
import VariablesTable from "views/Variables/Variables";
import MessagesPage from "views/Messages/messages.screen";
import ConversationsScreen from "views/Conversations/conversations.screen";

// icons available : https://themes-pixeden.com/font-demos/7-stroke/

const routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
  },
  {
    path: "/pictures",
    name: "Pictures",
    icon: "pe-7s-photo",
    collapse: true,
    views: [
      {
        path: "/pictures/nonSexual",
        layout: "/admin",
        name: "NON SEXUAL",
        mini: "NS",
        component: PicturesPage,
      },
      {
        path: "/pictures/softcore",
        layout: "/admin",
        name: "SOFTCORE",
        mini: "S",
        component: PicturesPage,
      },
      {
        path: "/pictures/someSkin",
        layout: "/admin",
        name: "SOME SKIN",
        mini: "SS",
        component: PicturesPage,
      },
      {
        path: "/pictures/hardcore",
        layout: "/admin",
        name: "HARD",
        mini: "H",
        component: PicturesPage,
      },
      {
        path: "/pictures/illegal",
        layout: "/admin",
        name: "ILLEGAL",
        mini: "I",
        component: PicturesPage,
      },
      {
        path: "/pictures/unAuthorized",
        layout: "/admin",
        name: "UNAUTHORIZED",
        mini: "U",
        component: PicturesPage,
      },
      {
        path: "/pictures/pending",
        layout: "/admin",
        name: "PENDING",
        mini: "P",
        component: PicturesPage,
      },
    ],
  },
  {
    path: "/videos",
    name: "Videos",
    icon: "pe-7s-video",
    collapse: true,
    views: [
      {
        path: "/videos/nonSexual",
        layout: "/admin",
        name: "NON SEXUAL",
        mini: "NS",
        component: VideoGrid,
      },
      {
        path: "/videos/softcore",
        layout: "/admin",
        name: "SOFTCORE",
        mini: "S",
        component: VideoGrid,
      },
      {
        path: "/videos/someSkin",
        layout: "/admin",
        name: "SOME SKIN",
        mini: "SS",
        component: VideoGrid,
      },
      {
        path: "/videos/hardcore",
        layout: "/admin",
        name: "HARD",
        mini: "H",
        component: VideoGrid,
      },
      {
        path: "/videos/illegal",
        layout: "/admin",
        name: "ILLEGAL",
        mini: "I",
        component: VideoGrid,
      },
      {
        path: "/videos/unAuthorized",
        layout: "/admin",
        name: "UNAUTHORIZED",
        mini: "U",
        component: VideoGrid,
      },
      {
        path: "/videos/pending",
        layout: "/admin",
        name: "PENDING",
        mini: "P",
        component: VideoGrid,
      },
    ],
  },
  {
    path: "/guys",
    layout: "/admin",
    name: "Guys of the week",
    component: GuysPage,
    icon: "pe-7s-users",
  },
  {
    path: "/users",
    layout: "/admin",
    name: "Users",
    component: UsersPage,
    icon: "pe-7s-users",
  },
  {
    path: "/sanctions",
    layout: "/admin",
    name: "Sanctions",
    component: SanctionsPage,
    icon: "pe-7s-attention",
  },
  {
    path: "/chat",
    layout: "/admin",
    name: "Messagerie",
    component: MessagesPage,
    icon: "pe-7s-mail-open-file",
  },
  {
    path: "/conversations",
    layout: "/admin",
    name: "Conversations",
    component: ConversationsScreen,
    icon: "pe-7s-chat",
  },
  {
    path: "/guides",
    layout: "/admin",
    name: "Guides",
    component: GuidesPage,
    icon: "pe-7s-bookmarks",
  },
  {
    path: "/live",
    layout: "/admin",
    name: "Live",
    component: LivePage,
    icon: "pe-7s-camera",
  },
  {
    path: "/history",
    layout: "/admin",
    name: "History",
    component: HistoryPage,
    icon: "pe-7s-monitor",
  },
  {
    path: "/subscriptions",
    layout: "/admin",
    name: "Subscriptions",
    component: SubscriptionsPage,
    icon: "pe-7s-piggy",
  },
  {
    path: "/reports",
    layout: "/admin",
    name: "Reports",
    component: ReportsPage,
    icon: "pe-7s-attention",
  },
  {
    path: "/accounting",
    layout: "/admin",
    name: "Accounting",
    component: AccountingPage,
    icon: "pe-7s-display1",
  },
  {
    path: "/login-page",
    layout: "/auth",
    name: "Login Page",
    component: LoginPage,
  },
  {
    path: "/variables",
    layout: "/admin",
    name: "Variables",
    icon: "pe-7s-edit",
    component: VariablesTable,
  },
];
export default routes;
