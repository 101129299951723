import { get, post } from "./utils.service";

export const fetchVideos = async (moderation) => {
  return await get(`/admin/videos?moderation=${moderation}`);
};

export const bulkModerateVideos = async (body) => {
    return await post("/admin/videos/bulk-moderate", body);
  };

export const deleteVideo = async (body) => {
  return await post("/admin/videos/delete", body);
};
