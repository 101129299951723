import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Select from "react-select";

import { moderatePicture } from "services/pictures.service";

const options = [
  {
    value: "nonSexual",
    label: "NON SEXUAL",
  },
  { value: "softcore", label: "SOFTCORE" },
  { value: "someSkin", label: "SOME SKIN" },
  { value: "hardcore", label: "HARD" },
  { value: "illegal", label: "ILLEGAL" },
  { value: "pending", label: "PENDING" },
  { value: "unAuthorized", label: "UNAUTHORIZED" },
];

export default function PostPicture({ picture }) {
  const [moderation, setModeration] = useState("");

  const moderatePictureHandler = (prevModeration, newModeration) => {
    if (prevModeration === newModeration) {
      return;
    }

    const body = {
      id: picture.id,
      moderation: newModeration.value,
      path: picture.path,
    };
    moderatePicture(body).then((res) => {
      if (!res.statusCode && !res.message) {
        setModeration(newModeration);
      } else {
        setModeration(prevModeration);
      }
    });
  };

  useEffect(() => {
    const pictureModeration =
      picture.moderationAdmin !== "pending"
        ? picture.moderationAdmin
        : picture.moderationRating !== "pending"
        ? picture.moderationRating
        : picture.moderationHive;
    setModeration(pictureModeration);
  }, [picture]);

  return (
    <PostImage>
      <img alt="" src={picture.path} />
      <Select
        className="react-select primary"
        classNamePrefix="react-select"
        name="singleSelect"
        value={options.find((option) => option.value === moderation)}
        onChange={(value) => {
          moderatePictureHandler(moderation, value);
        }}
        options={options}
        placeholder="Change moderation"
      />
    </PostImage>
  );
}
const PostImage = styled.div`
  display: flex;
  flex-direction: column;

  & img {
    background-color: #eee;
    width: 300px;
    height: 300px;
    object-fit: contain;
  }
`;
