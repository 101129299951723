import React, { useState } from "react";
import { Alert } from "react-bootstrap";

import SaveGuideForm from "./save-guide.form";

import { createGuide } from "../../../services/guides.service";
import { validWebsite } from "../../../utils/regex.utils";

export default function CreateGuide() {
  const [status, setStatus] = useState("idle"); // "idle" | "loading" | "error" | "success"
  const [errorMessage, setErrorMessage] = useState("");

  /**
   * Fonction permettant de vérifier si l'input actuel est satisfaisant pour la création d'un guide.
   */
  const guideHasErrors = (body) => {
    let errorMessage = "";
    const hasDuration =
      body.type === "event" ||
      body.type === "accommodation" ||
      body.type === "art_culture";

    if (!body.title) {
      errorMessage = "You must indicate a title.";
    }
    if (!body.description) {
      errorMessage = "You must indicate a description.";
    }
    if (!body.city) {
      errorMessage = "You must indicate a city.";
    }
    if (!body.venue) {
      errorMessage = "You must indicate a venue.";
    }
    if (!body.longitude || !body.latitude || !body.address) {
      errorMessage =
        'You must choose an autocompleted address in the "Full address" field.';
    }
    if (!body.organizer) {
      errorMessage = "You must indicate an organizer.";
    }
    if (!validWebsite(body.website)) {
      errorMessage = "You must indicate a valid website.";
    }
    if (!!body.ticket && !validWebsite(body.ticket)) {
      errorMessage = "The ticket purchase website is not a valid URL.";
    }
    if (body.picture.path === null) {
      errorMessage = "You must upload a picture.";
    }

    if (hasDuration) {
      if (!body.startDatetime) {
        errorMessage = "You must indicate a start date & time.";
      } else if (!body.endDatetime) {
        errorMessage = "You must indicate an end date & time.";
      } else if (new Date(body.startDatetime) > new Date(body.endDatetime)) {
        errorMessage =
          "You cannot indicate a starting date older than the end date.";
      }
    }

    if (!!errorMessage) {
      setErrorMessage(errorMessage);
    }
    return !!errorMessage;
  };

  const submitGuide = (body) => {
    if (status === "loading" || status === "success") {
      return;
    }

    const createBody = {
      ...body,
      adminCreated: true,
    };

    const hasDuration =
      body.type === "event" ||
      body.type === "accommodation" ||
      body.type === "art_culture";

    if (hasDuration) {
      createBody.startDatetime = body.startDatetime
        ? new Date(body.startDatetime).toISOString()
        : null;
      createBody.endDatetime = body.endDatetime
        ? new Date(body.endDatetime).toISOString()
        : null;
    }

    if (guideHasErrors(createBody)) {
      setStatus("error");
      return;
    }

    setStatus("loading");
    createGuide(createBody).then((res) => {
      if (res?.success) {
        setStatus("success");
      } else {
        setErrorMessage(
          "An error occured during the creation of your guide, please try again."
        );
        setStatus("error");
      }
    });
  };

  return (
    <>
      <SaveGuideForm
        saveGuideHandler={submitGuide}
        status={status}
        errorMessage={errorMessage}
      />
      {status === "success" && (
        <Alert variant="info" className="alert-info">
          Your guide was successfully created !
        </Alert>
      )}
    </>
  );
}
