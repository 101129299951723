const environment =
  process.env.NODE_ENV === "development"
    ? { apiUrl: "http://localhost:3000/api" }

    // For now until the prod API is updated
    : { apiUrl: "https://api.dev.bearwww.com/api" };

const config = {
  environment,
};

export default config;
