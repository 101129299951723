import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Loader from "react-loader-spinner";
import {
  Alert,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import {
  createForbiddenWord,
  getForbiddenWords,
} from "services/sanctions.service";

export default function ForbiddenWords() {
  const [status, setStatus] = useState("idle"); // ENUM ("idle" | "loading" | "error" | "success")
  const [forbiddenWords, setForbiddenWords] = useState([]);
  const [forbiddenWord, setForbiddenWord] = useState("");
  const [refreshList, setRefreshList] = useState(0);

  useEffect(() => {
    (async () => {
      const forbiddenWords = await getForbiddenWords();
      setForbiddenWords(forbiddenWords);
    })();
  }, [refreshList]);

  const addKeywordHandler = () => {
    if (status === "loading") {
      return;
    }
    setStatus("loading");
    createForbiddenWord({
      forbiddenWord: forbiddenWord.trim(),
    }).then((res) => {
      setStatus("success");
      setRefreshList(Math.random());
    });
  };

  return (
    <>
      <Form horizontal>
        <div style={{ marginBottom: 30 }}>
          When a user types one these keywords in a private message or in its
          description, he will be automatically banned.
        </div>
        <fieldset>
          <FormGroup>
            <ControlLabel className="col-sm-2">Keyword</ControlLabel>
            <Col sm={10}>
              <FormControl
                type="text"
                placeholder="adangerouswebsite.com"
                name="forbiddenWord"
                onChange={(e) => setForbiddenWord(e.target.value)}
                value={forbiddenWord}
              />
            </Col>
          </FormGroup>
        </fieldset>
        <fieldset>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button onClick={addKeywordHandler}>Add</Button>
            {status === "loading" && (
              <div style={{ marginleft: 8 }}>
                <Loader />
              </div>
            )}
          </div>
        </fieldset>
        {status === "success" && (
          <MarginWrapper>
            <Alert className="alert-info">
              Your keyword has been successfully added!
            </Alert>
          </MarginWrapper>
        )}
      </Form>
      <div style={{ marginTop: 30 }}>
        Sanction-triggering keywords :
        <ul>
          {forbiddenWords.map((x) => (
            <li>{x.forbiddenWord}</li>
          ))}
        </ul>
      </div>
    </>
  );
}

const MarginWrapper = styled.div`
  margin: 8px 0;
`;

const Autocomplete = styled.div`
  position: relative;
`;

const AutocompleteInput = styled.input`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
`;
