import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Loader from "react-loader-spinner";
import { Alert, Col, FormGroup, ControlLabel, Form } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import { unbanUser } from "services/sanctions.service";

export default function CreateSanction() {
  const [status, setStatus] = useState("idle"); // ENUM ("idle" | "loading" | "error" | "success")
  const [errorMessage, setErrorMessage] = useState("");

  const [pseudo, setPseudo] = useState("");

  const submitSanctionHandler = () => {
    if (status === "loading") {
      return;
    }
    setStatus("loading");
    const body = {
      pseudo: pseudo.trim(),
    };
    unbanUser(body).then((res) => {
      if (res?.success) {
        setStatus("success");
      } else {
        setErrorMessage(res.error || "An error occured, please try again.");
        setStatus("error");
      }
    });
  };

  return (
    <Form horizontal>
      <div style={{ marginBottom: 30 }}>
        When you unban a user, it deletes all sanctions associated to this user,
        and removes the IP addresses that were marked as banned.
      </div>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">User</ControlLabel>
          <Col sm={10}>
            <Autocomplete>
              <AutocompleteInput
                type="text"
                placeholder="User"
                name="user"
                onChange={(e) => setPseudo(e.target.value)}
                value={pseudo}
                autoComplete="off"
              />
            </Autocomplete>
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button onClick={submitSanctionHandler}>Unban</Button>
          {status === "loading" && (
            <div style={{ marginleft: 8 }}>
              <Loader />
            </div>
          )}
        </div>
      </fieldset>
      {status === "error" && (
        <MarginWrapper>
          <Alert className="alert-danger">{errorMessage}</Alert>
        </MarginWrapper>
      )}
      {status === "success" && (
        <MarginWrapper>
          <Alert className="alert-info">This user can use Bearwww again!</Alert>
        </MarginWrapper>
      )}
    </Form>
  );
}

const MarginWrapper = styled.div`
  margin: 8px 0;
`;

const Autocomplete = styled.div`
  position: relative;
`;

const AutocompleteInput = styled.input`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
`;
