import { get, post } from "./utils.service";

export const getSanctions = async (selectMode) => {
  return await get(`/admin/sanctions?mode=${selectMode}`);
};

export const moderateSanction = async (body) => {
  return await post("/admin/sanctions/moderate", body);
};

export const createSanction = async (body) => {
  return await post("/admin/sanctions/create", body);
};

export const unbanUser = async (body) => {
  return await post("/admin/sanctions/unban-user", body);
};

export const createForbiddenWord = async (body) => {
  return await post("/admin/sanctions/create-forbidden-word", body);
};

export const getForbiddenWords = async (body) => {
  return await get("/admin/sanctions/forbidden-words");
};
