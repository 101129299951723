export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export function validDaily(url) {
  var p = /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/;
  if (url.match(p)) {
    return RegExp.$2;
  }
  var p = /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\/_]+)))$/;
  if (url.match(p)) {
    return RegExp.$2;
  }
  return false;
}

export function validYT(url) {
  var p = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=((\w|-){11}))(?:\S+)?$/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  var p = /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
  if (url.match(p)) {
    return RegExp.$1;
  }
  return false;
}

export const validWebsite = (value) => {
  return value.match(
    /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
};

/* PASSWORD-RELATED */

export const containSpecialCharacter = (value) => {
  return value.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
};

export const containLowercaseCharacter = (value) => {
  return value.match(/[a-z]/);
};

export const containUppercaseCharacter = (value) => {
  return value.match(/[A-Z]/);
};

export const containNumber = (value) => {
  return value.match(/[0-9]/);
};

export const calculatePasswordStrength = (password) => {
  let strength = 0;
  if (containSpecialCharacter(password)) strength = strength + 2;
  if (containLowercaseCharacter(password)) strength++;
  if (containUppercaseCharacter(password)) strength++;
  if (containNumber(password)) strength++;
  if (password.length > 3) strength++;
  if (password.length > 7) strength++;
  return strength;
};
