import React from "react";
import Modal from "react-modal";
import styled from "styled-components/macro";

Modal.setAppElement("#root");

export default function ModalTemplate({
  showModal,
  closeModal,
  children,
  width,
  canSelfClose = true,
}) {
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={canSelfClose ? closeModal : () => {}}
      style={modalCustomStyles}
    >
      <ModalBody width={width}>
        {canSelfClose && (
          <CloseButtonWrapper>
            <div onClick={closeModal}>&#10006;</div>
          </CloseButtonWrapper>
        )}
        {children}
      </ModalBody>
    </Modal>
  );
}

const modalCustomStyles = {
  content: {
    borderRadius: 4,
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -30%)",
    padding: "10px",
    zIndex: 101,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 100,
  },
};

const ModalBody = styled.div`
  display: block;
  width: ${(props) => (props.width ? props.width : "640px")};
  padding: 0;
`;

const CloseButtonWrapper = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
  margin-bottom: 15px;

  & > div {
    z-index: 101;
    cursor: pointer;
    user-select: none;
  }
`;
