import Cookies from "js-cookie";
import { get, put } from "services/utils.service";

const COOKIE_NAME = "variablesCache";
const getDistantVariables = async () => await get("/variables");
const updateDistantVariableById = async (id, data) => {
  await put(`/variables/${id}`, data);
  Cookies.remove(COOKIE_NAME);
};

export const getOrUpdateVariables = async () => {
  const cache = Cookies.get(COOKIE_NAME);
  if (cache) {
    return JSON.parse(cache);
  }
  const json = await getDistantVariables();
  const inOneMinute = new Date(new Date().getTime() + 1 * 60 * 1000);
  Cookies.set(COOKIE_NAME, JSON.stringify(json), {
    expires: inOneMinute,
  });
  return json;
};

export const getVariableById = async (id) => {
  const variables = await getOrUpdateVariables();
  return variables.find((item) => item.id === id);
};

export const updateVariableById = async (id, data) => {
  const variables = await getOrUpdateVariables();
  //we can update the variable directly and not invalidate cookie cache
  await updateDistantVariableById(id, data);
};
