import Card from "components/Card/Card.jsx";
import { Col, Row } from "react-bootstrap";
import { useState, useMemo, useEffect } from "react";
import "./../../App.css";
import {
  getUserConversations,
  getConversationMessages,
} from "services/chat.service";

function MessagesView({ messages, onLoadMore, currentUserId }) {
  return (
    <div
      style={{
        height: "600px",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      {messages.map((message) => (
        <div
          style={{
            border: "1px solid lightgray",
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 0,
            paddingTop: 12,
            margin: 6,
            borderRadius: 6,
            borderTopLeftRadius: message.sender.id === currentUserId ? 0 : 6,
            borderTopRightRadius: message.sender.id === currentUserId ? 6 : 0,
            marginLeft: message.sender.id === currentUserId ? 0 : "25%",
            marginRight: message.sender.id === currentUserId ? "25%" : 0,
            backgroundColor:
              message.sender.id === currentUserId
                ? "rgba(230, 230, 230, 255)"
                : "white",
          }}
        >
          <p>{message.content}</p>
          {message.type === "video" && (
            <video
              src={message.pictures[0].path}
              controls
              style={{ width: "100%", height: "auto" }}
            />
          )}
          {message.type === "text" &&
            message.pictures.map((picture) => (
              <img
                src={picture.path}
                alt={picture.path}
                style={{
                  width: message.pictures.length === 1 ? "100%" : "50%",
                  height: "auto",
                  padding: 2,
                }}
              />
            ))}
          <p
            style={{
              fontSize: 12,
              color: "gray",
            }}
          >{`${new Date(message.createdAt).toLocaleTimeString(
            "Fr-fr"
          )} - ${new Date(message.createdAt).toLocaleDateString("Fr-fr")}`}</p>
        </div>
      ))}
      {messages.length % 10 === 0 && (
        <a style={{ cursor: "pointer" }} onClick={onLoadMore}>
          Load more
        </a>
      )}
    </div>
  );
}

function MessageExpander({ conversationId, currentUser }) {
  const [messages, setMessages] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [page, setPage] = useState(0);

  const handleShowMessage = async () => {
    const result = await getConversationMessages(
      conversationId,
      currentUser.pseudo,
      page
    );
    if (result) {
      setMessages(result.reverse());
    }
    setShowMessage(true);
  };

  const handleLoadMore = async () => {
    const result = await getConversationMessages(
      conversationId,
      currentUser.pseudo,
      page + 1
    );
    if (result) {
      setMessages([...messages, ...result.reverse()]);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    setShowMessage(false);
  }, [currentUser]);

  if (!currentUser) {
    return null;
  }

  return showMessage ? (
    <div>
      <MessagesView
        messages={messages}
        onLoadMore={handleLoadMore}
        currentUserId={currentUser.id}
      />
      <a style={{ cursor: "pointer" }} onClick={() => setShowMessage(false)}>
        Hide messages
      </a>
    </div>
  ) : (
    <a style={{ cursor: "pointer" }} onClick={handleShowMessage}>
      Show messages
    </a>
  );
}

function ConversationList({ conversations, currentUserPseudo, onLoadMore }) {
  if (conversations.length === 0) {
    return <div>No conversations found</div>;
  }

  const getCurrentUser = (conversation) => {
    const members = conversation.conversation.conversation_users.map(
      (u) => u.user
    );
    const user = members.find((u) => u.pseudo.toLowerCase() === currentUserPseudo.toLowerCase());
    return user;
  };

  const getConversationTitle = (conversation) => {
    const members = conversation.conversation.conversation_users.map(
      ({ user }) => user.pseudo
    );
    return members.filter((pseudo) => pseudo !== currentUserPseudo)[0];
  };

  return (
    <div style={{ width: "100%" }}>
      {conversations.map((conversation) => (
        <Card
          title={getConversationTitle(conversation)}
          category={conversation.content}
          id={conversation?.id}
          content={
            <MessageExpander
              conversationId={conversation?.conversation?.id}
              currentUser={getCurrentUser(conversation)}
            />
          }
        />
      ))}
      <a onClick={onLoadMore} style={{ cursor: "pointer" }}>
        Load more
      </a>
    </div>
  );
}

function SearchForm({ onSubmit }) {
  const [pseudo, setPseudo] = useState("");

  return (
    <div>
      <Col>
        <label>Conversations from user : </label>
        <input
          type="text"
          required
          placeholder="pseudo..."
          value={pseudo}
          onChange={(e) => setPseudo(e.target.value)}
          style={{ height: 40, width: "200px", padding: 6, marginLeft: 12 }}
        />

        <button
          style={{
            height: 40,
            width: "100px",
            padding: 6,
            marginLeft: 12,
          }}
          type="submit"
          onClick={() => {
            onSubmit(pseudo);
          }}
        >
          Search
        </button>
      </Col>
    </div>
  );
}

const numberOfConversations = 10;

export default function ConversationsScreen() {
  const [pseudo, setPseudo] = useState("");
  const [page, setPage] = useState(0);

  const handleSubmit = (pseudo) => {
    setPseudo(pseudo);
  };

  const [conversations, setConversations] = useState([]);

  useEffect(async () => {
    if (pseudo !== "") {
      const result = await getUserConversations(
        pseudo,
        page,
        numberOfConversations
      );
      if (result) {
        setConversations(result);
      }
    } else {
      setConversations([]);
    }
  }, [pseudo]);

  const handleLoadMore = async () => {
    const result = await getUserConversations(
      pseudo,
      page + 1,
      numberOfConversations
    );
    if (result) {
      setConversations([...conversations, ...result]);
      setPage(page + 1);
    }
  };

  return (
    <div className="main-content">
      <Card
        title="Utilisateur"
        category={pseudo}
        style={{ width: "100%" }}
        content={<SearchForm onSubmit={handleSubmit} />}
      />

      <Card
        title="Conversations"
        content={
          <ConversationList
            conversations={conversations}
            currentUserPseudo={pseudo}
            onLoadMore={handleLoadMore}
          />
        }
      />
    </div>
  );
}
