import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import styled from "styled-components/macro";
import moment from "moment";

import Select from "components/Form/select.input";
import TextInput from "components/Form/text.input";

import {
  getExchangeRateByMonthAndYear,
  generateStatistics,
} from "services/accounting.service";

import { currencies } from "constants/currencies.constant";
import DisplayStatistics from "./display-statistics.component";

export default function DisplayAccounting() {
  const [status, setStatus] = useState("idle"); // ENUM ("idle" | "loading" | "error" | "success")
  const [errorMessage, setErrorMessage] = useState("");

  const [displayMode, setDisplayMode] = useState("show-params"); // ENUM ("show-params" | "show-stats")
  const [periodMode, setPeriodMode] = useState("monthly"); // ENUM ("monthly" | "specific")
  const [yearOptions, setYearOptions] = useState([]);
  const [exchangeRateConstant, setExchangeRateConstant] = useState(null);

  const [monthSelected, setMonthSelected] = useState(moment().format("MM"));
  const [yearSelected, setYearSelected] = useState(new Date().getFullYear());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currencySelected, setCurrencySelected] = useState("EUR");
  const [exchangeRateInputed, setExchangeRateInputed] = useState("");

  const [statistics, setStatistics] = useState(null);

  const generateStatisticsHandler = () => {
    if (status === "loading" || status === "success") {
      return;
    }

    // !! TODO : pour la mise en prod, il faudra adapter la date en fonction du mois de déploiement
    if (
      periodMode === "monthly" &&
      +monthSelected < 4 &&
      yearSelected === 2021
    ) {
      setStatus("error");
      setErrorMessage(
        "Statistics before the month of April are unavailable, please choose a later month."
      );
      return;
    }

    if (
      periodMode === "specific" &&
      moment(startDate, "YYYY-MM-DD").isBefore(
        moment("2021-04-01", "YYYY-MM-DD")
      )
    ) {
      setStatus("error");
      setErrorMessage(
        "Statistics before the month of April are unavailable, please choose a later date."
      );
      return;
    }

    if (+exchangeRateInputed < 0) {
      setStatus("error");
      setErrorMessage("Exchange rates cannot be negative numbers.");
      return;
    }

    const newExchangeRates = {
      ...exchangeRateConstant,
      [currencySelected]: exchangeRateInputed,
    };

    const body = {
      exchangeRates: newExchangeRates,
      mode: periodMode,
      currency: currencySelected,
    };

    if (periodMode === "monthly") {
      body.month = +monthSelected;
      body.year = +yearSelected;
    } else if (periodMode === "specific") {
      body.startDate = startDate;
      body.endDate = endDate;
    }

    setStatus("loading");
    generateStatistics(body).then((res) => {
      if (!res.error) {
        setExchangeRateConstant(newExchangeRates);
        setDisplayMode("show-stats");
        setStatus("success");
        setStatistics(res.statistics);
      } else {
        setStatus("error");
        setErrorMessage(
          "Something went wrong while generating subscriptions statistics, please try again later or contact the technical support."
        );
      }
    });
  };

  // permet de modifier dynamiquement le taux de change en fonction de la monnaie
  useEffect(() => {
    if (!!exchangeRateConstant) {
      setExchangeRateInputed(exchangeRateConstant[currencySelected] || 1);
    }
  }, [currencySelected, exchangeRateConstant]); // eslint-disable-line

  // récupère les taux de changes via l'API en fonction du mois
  useEffect(() => {
    getExchangeRateByMonthAndYear(+monthSelected, +yearSelected).then((res) => {
      if (!res.error) {
        setExchangeRateConstant(res);
      }
    });
  }, [monthSelected]);

  // réinitialisation des données lors d'un changement de mode
  useEffect(() => {
    setMonthSelected(moment().format("MM"));
    setYearSelected(new Date().getFullYear());
    setStartDate("");
    setEndDate("");
  }, [periodMode]);

  // permet de générer automatiquement les options pour l'année
  useEffect(() => {
    const yearList = [];
    for (let year = 2021; year <= new Date().getFullYear(); year++) {
      yearList.push(year);
    }
    setYearOptions(yearList);
  }, []);

  return (
    <>
      {displayMode === "show-params" && (
        <MainWrapper>
          <SectionLabel>Period</SectionLabel>
          <PeriodSelector>
            <Select
              value={periodMode}
              onChange={(e) => {
                if (status !== "loading") {
                  setPeriodMode(e.target.value);
                }
              }}
            >
              <option value="monthly" selected>
                Monthly
              </option>
              <option value="specific">Specific period</option>
            </Select>
            {periodMode === "monthly" && (
              <MonthSelector>
                <div>Month {"&"} Year :</div>
                <div className="inputs">
                  <Select
                    value={monthSelected}
                    onChange={(e) => {
                      if (status !== "loading") {
                        setMonthSelected(e.target.value);
                      }
                    }}
                  >
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </Select>
                  <Select
                    value={yearSelected}
                    onChange={(e) => {
                      if (status !== "loading") {
                        setYearSelected(+e.target.value);
                      }
                    }}
                  >
                    {yearOptions.map((year) => (
                      <option value={year} key={year}>
                        {year}
                      </option>
                    ))}
                  </Select>
                </div>
              </MonthSelector>
            )}
            {periodMode === "specific" && (
              <DatesSelector>
                <label>
                  <span>Start date :</span>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      if (status !== "loading") {
                        setStartDate(e.target.value);
                      }
                    }}
                  />
                </label>
                <label>
                  <span>End date :</span>
                  <input
                    type="date"
                    value={endDate}
                    disabled={status === "loading"}
                    onChange={(e) => {
                      if (status !== "loading") {
                        setEndDate(e.target.value);
                      }
                    }}
                  />
                </label>
              </DatesSelector>
            )}
          </PeriodSelector>
          <SectionLabel>Currency</SectionLabel>
          <CurrencySelector>
            <Select
              value={currencySelected}
              onChange={(e) => {
                if (status !== "loading") {
                  setCurrencySelected(e.target.value);
                }
              }}
            >
              {currencies.map((currency) => (
                <option key={currency.value} value={currency.value}>
                  {currency.label}
                </option>
              ))}
            </Select>
            {currencySelected !== "EUR" && periodMode === "monthly" && (
              <ExchangeRateWrapper>
                <span>Exchange rate : 1€ = </span>
                <TextInput
                  type="number"
                  disabled={status === "loading"}
                  min={0}
                  value={exchangeRateInputed}
                  onChange={(e) => setExchangeRateInputed(e.target.value)}
                />
                <span>{currencySelected}</span>
              </ExchangeRateWrapper>
            )}
          </CurrencySelector>
          <SubmitWrapper>
            {status === "error" && (
              <Alert className="alert-danger">{errorMessage}</Alert>
            )}
            <Button
              className="btn-fill btn-info"
              onClick={generateStatisticsHandler}
            >
              Display data
            </Button>
          </SubmitWrapper>
        </MainWrapper>
      )}
      {displayMode === "show-stats" && (
        <div>
          <ButtonWrapper>
            <Button
              className="btn-info"
              onClick={() => {
                setDisplayMode("show-params");
                setStatus("idle");
              }}
            >
              Retour
            </Button>
          </ButtonWrapper>
          <DisplayStatistics
            statistics={statistics}
            currency={currencySelected}
            exchangeRateConstant={exchangeRateConstant}
            periodLabel={
              periodMode === "monthly"
                ? `${monthSelected}/${yearSelected}`
                : `
                ${moment(startDate).format("DD/MM/YYYY")}${" "}
                to${" "}
                ${moment(endDate).format("DD/MM/YYYY")}`
            }
          />
        </div>
      )}
    </>
  );
}

const MainWrapper = styled.div`
  width: 600px;
  --section-margin-bottom: 24px;
`;

const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #888;
  margin-bottom: 8px;
`;

const PeriodSelector = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--section-margin-bottom);
`;

const MonthSelector = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  & .inputs {
    margin-left: 8px;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  & .inputs > * {
    width: 48%;
  }
`;

const DatesSelector = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    width: 48%;
    display: flex;
    align-items: center;

    & > span {
      margin-right: 8px;
    }

    & > input {
      flex: 1;
      background-color: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      color: #565656;
      padding: 8px 12px;
      height: 40px;
      box-shadow: none;
      outline: none;
    }
  }
`;

const CurrencySelector = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--section-margin-bottom);
`;

const ExchangeRateWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  & > span:first-child {
    margin-right: 8px;
  }

  & > span:last-child {
    margin-left: 8px;
  }

  & > input {
    flex: 1;
  }
`;

const SubmitWrapper = styled.div`
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`;
