/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import UserDetail from "./VariableDetail";
import * as queryString from "query-string";
import { getOrUpdateVariables } from "services/variables.service";
const CACHE = {};
class VariablesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
      data: [],
      variableSelected: null,
    };
  }

  createDataTable = (variables) => {
    let data = variables.map((variable, key) => {
      return {
        id: variable.id,
        key_name: variable.key_name,
        value: variable.value,
        description: variable.description,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                let obj = this.state.data.find((o) => o.id === variable.id);
                this.setState({
                  showDetail: true,
                  variableSelected: obj.id,
                });
              }}
              bsStyle="warning"
              simple
              icon
            >
              <i className="fa fa-edit" />
            </Button>{" "}
          </div>
        ),
      };
    });
    this.setState({
      data,
    });
  };

  render() {
    return (
      <div className="main-content" style={{ position: "relative" }}>
        {this.state.showDetail && (
          <div
            className="fa fa-times"
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              cursor: "pointer",
              fontSize: 30,
            }}
            onClick={() => {
              this.setState({ showDetail: false });
            }}
          />
        )}
        {this.state.showDetail ? (
          <UserDetail
            id={this.state.variableSelected}
            onUpdate={() => this.setState({ showDetail: false })}
          />
        ) : (
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Variables List(updated every minutes)"
                  content={
                    <ReactTable
                      data={this.state.data}
                      // filterable
                      // sortable={false}
                      columns={[
                        {
                          Header: "Key",
                          accessor: "key_name",
                        },
                        {
                          Header: "Value",
                          accessor: "value",
                        },

                        {
                          Header: "Description",
                          accessor: "description",
                          sortable: false,
                          filterable: false,
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      showPageSizeOptions={false}
                      showPaginationTop
                      showPaginationBottom={false}
                      loading={this.state.loading}
                      className="-striped -highlight"
                      manual
                      onFetchData={async (state) => {
                        this.setState({ loading: true });
                        const json = await getOrUpdateVariables();
                        this.createDataTable(json);
                        this.setState({
                          loading: false,
                        });
                      }}
                    />
                  }
                />
              </Col>
            </Row>
          </Grid>
        )}
      </div>
    );
  }
}

export default VariablesTable;
