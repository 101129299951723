import React, { useState, useEffect } from "react";
import { Alert, Button, FormGroup, ControlLabel, Col } from "react-bootstrap";
import styled from "styled-components/macro";
import Loader from "react-loader-spinner";

import Select from "components/Form/select.input";

import { createSubscription } from "services/subscriptions.service";

export default function GivePremium() {
  const [status, setStatus] = useState("idle"); // ENUM (idle | loading | error | success)
  const [errorMessage, setErrorMessage] = useState("");

  const [duration, setDuration] = useState("1WEEK"); // ENUM (1WEEK | 1MONTH | 3MONTHS | 6MONTHS | 1YEAR)
  const [pseudo, setPseudo] = useState("");

  const givePremiumHandler = () => {
    if (status === "loading") {
      return;
    }

    const formData = {
      method: "BO_GIFTED",
      pseudo: pseudo.trim(),
      duration,
    };

    setErrorMessage("");
    setStatus("loading");
    createSubscription(formData).then((res) => {
      console.log("res", res);
      if (res && !res?.error) {
        setStatus("success");
      } else {
        setStatus("error");
        let errorMsg =
          "Something went wrong while giving this user premium, please try again later or contact technical support.";
        if (res.error == "User not found") {
          errorMsg = res.error;
        } else if (
          res.error == "account.settings.premium_code.error_user_subscribed"
        ) {
          errorMsg = "This user is already subscribed";
        }
        setErrorMessage(errorMsg);
      }
    });
  };

  return (
    <Wrapper>
      <FormWrapper>
        <fieldset>
          <FormGroup>
            <ControlLabel className="col-sm-2">User</ControlLabel>
            <Col sm={10}>
              <Autocomplete>
                <AutocompleteInput
                  type="text"
                  placeholder="User"
                  name="user"
                  list={""}
                  onChange={(e) => setPseudo(e.target.value)}
                  value={pseudo}
                  autoComplete="off"
                />
              </Autocomplete>
            </Col>
          </FormGroup>
        </fieldset>
        <fieldset>
          <FormGroup>
            <ControlLabel className="col-sm-2">Duration</ControlLabel>
            <Col sm={10}>
              <Select
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
              >
                <option value="1WEEK">1 week</option>
                <option value="1MONTH">1 month</option>
                <option value="3MONTHS">3 months</option>
                <option value="6MONTHS">6 months</option>
                <option value="1YEAR">1 year</option>
              </Select>
            </Col>
          </FormGroup>
        </fieldset>
        {status === "error" && (
          <fieldset>
            <Alert variant="danger" className="alert-danger">
              {errorMessage}
            </Alert>
          </fieldset>
        )}
        {status === "success" && (
          <fieldset>
            <Alert variant="info" className="alert-info">
              You successfully gifted a premium to{" "}
              <strong>{pseudo.trim()}</strong>.
            </Alert>
          </fieldset>
        )}
        <fieldset>
          {status === "loading" ? (
            <Loader type="Circles" color="#9e9e9e" height={25} width={25} />
          ) : (
            <Button onClick={givePremiumHandler}>Give premium</Button>
          )}
        </fieldset>
      </FormWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 16px;
  }
`;

const Autocomplete = styled.div`
  position: relative;
`;

const AutocompleteInput = styled.input`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
`;
