/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import UserDetail from "./UserDetail";
import * as queryString from "query-string";
import { get } from "services/utils.service";
import moment from "moment";
("");
class UsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
      data: [],
      userSelected: null,
    };
  }

  getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  createDataTable = (users) => {
    let data = users.map((user, key) => {
      return {
        id: user.id,
        pseudo: user.pseudo,
        email: user.email,
        age: this.getAge(user.birthdate),
        createdAt: moment(user.createdAt).format("D MMMM YYYY [at] HH:mm"),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                let obj = this.state.data.find((o) => o.id === user.id);
                this.setState({
                  showDetail: true,
                  userSelected: obj.id,
                });
              }}
              bsStyle="warning"
              simple
              icon
            >
              <i className="fa fa-edit" />
            </Button>{" "}
            {/* use this button to remove the data row */}
            {/* <Button
                            onClick={() => {
                                var data = this.state.data;
                                data.find((o, i) => {
                                    if (o.id === key) {
                                        // here you should add some custom code so you can delete the data
                                        // from this component and from your server as well
                                        data.splice(i, 1);
                                        console.log(data);
                                        return true;
                                    }
                                    return false;
                                });
                                this.setState({ data: data });
                            }}
                            bsStyle="danger"
                            simple
                            icon
                        >
                            <i className="fa fa-times" />
                        </Button>{" "} */}
          </div>
        ),
      };
    });
    this.setState({
      data,
    });
  };

  render() {
    return (
      <div className="main-content" style={{ position: "relative" }}>
        {this.state.showDetail && (
          <div
            className="fa fa-times"
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              cursor: "pointer",
              fontSize: 30,
            }}
            onClick={() => {
              this.setState({ showDetail: false });
            }}
          />
        )}
        {this.state.showDetail ? (
          <UserDetail id={this.state.userSelected} />
        ) : (
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="User List"
                  content={
                    <ReactTable
                      data={this.state.data}
                      filterable
                      sortable={false}
                      columns={[
                        {
                          id: "checkbox",
                          accessor: "",
                          Cell: ({ original }) => {
                            return (
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.props?.selected
                                  ?.map((u) => u.id)
                                  .includes(original.id)}
                                onChange={() => {
                                  this.props?.onUserCheck?.(original);
                                }}
                              />
                            );
                          },
                          sortable: false,
                          filterable: false,
                          width: 45,
                        },
                        {
                          Header: "Pseudo",
                          accessor: "pseudo",
                        },
                        {
                          Header: "Email",
                          accessor: "email",
                        },

                        {
                          Header: "Age",
                          accessor: "age",
                          sortable: false,
                          filterable: false,
                        },

                        {
                          Header: "Registration date",
                          accessor: "createdAt",
                          sortable: false,
                          filterable: false,
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={30}
                      showPageSizeOptions={false}
                      showPaginationTop
                      showPaginationBottom={false}
                      pages={30}
                      loading={this.state.loading}
                      className="-striped -highlight"
                      manual
                      onFetchData={async (state) => {
                        this.setState({ loading: true });
                        const json = await get(
                          "/admin/users?" +
                            queryString.stringify({
                              page: state.page,
                              pageSize: state.pageSize,
                              filtered: JSON.stringify(state.filtered),
                            })
                        );
                        this.createDataTable(json);
                        this.setState({
                          loading: false,
                        });
                      }}
                    />
                  }
                />
              </Col>
            </Row>
          </Grid>
        )}
      </div>
    );
  }
}

export default UsersTable;
