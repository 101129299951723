export const currencies = [
  {
    value: "EUR",
    label: "Euro",
  },
  {
    value: "USD",
    label: "US dollars",
  },
  {
    value: "DKK",
    label: "Danish krone",
  },
  {
    value: "NOK",
    label: "Norwegian krone",
  },
  {
    value: "SEK",
    label: "Swedish krone",
  },
  {
    value: "AUD",
    label: "Australian dollars",
  },
  {
    value: "CAD",
    label: "Canadian dollars",
  },
  {
    value: "CHF",
    label: "Swiss franc",
  },
  {
    value: "GBP",
    label: "British pound",
  },
  {
    value: "ZAR",
    label: "South African rand",
  },
  {
    value: "JPY",
    label: "Japanese yen",
  },
  {
    value: "BRL",
    label: "Brazilian real",
  },
  {
    value: "MXN",
    label: "Mexican peso",
  },
  {
    value: "KRW",
    label: "South Korean won",
  },
];
