import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { Alert } from "react-bootstrap";

import ModalTemplate from "../../../components/Modal/modal-template.component";
import PostPicture from "../components/post-picture.component";
import {
  mapSanctionTypeToLabel,
  mapSanctionStatusToLabel,
  Badge,
} from "../components/select-sanctions.component";

import { getUserReports } from "../../../services/reports.service";
import { getUserLastMessages } from "../../../services/users.service";

import { ReactComponent as ImagesIcon } from "../../../assets/svg/images.svg";

const mapReportSourceToLabel = {
  user: "User",
  hive: "Hive",
};

const mapReportTypeToLabel = {
  user: "User's report",
  post: "Post's report",
  live: "Live's report",
  stories: "Stories' report",
  comment: "Comment's report",
};

const mapReportMotiveToLabel = {
  illegal: "Illegal activities",
  spam_scam: "Spam / Scam",
  bullying: "Harassment / Bullying",
  hate_discrimination: "Hate speech / Discrimination",
  nudity_pornography: "Nudity / Pornography",
  minor: "Minor",
  identity_theft: "Identity theft",
};

export default function CheckSanctionModal({
  showModal,
  closeModal,
  sanction,
}) {
  const [userReports, setUserReports] = useState([]);
  const [userLastMessages, setUserLastMessages] = useState([]);

  useEffect(() => {
    if (showModal && !!sanction) {
      getUserReports(sanction?.user?.id).then((reports) => {
        if (Array.isArray(reports)) {
          setUserReports(reports);
        }
      });
    }
  }, [showModal, sanction]);

  useEffect(() => {
    if (showModal && !sanction) {
      closeModal();
    }
  }, [showModal, sanction]);

  useEffect(() => {
    if (!showModal) {
      setUserReports([]);
    }
  }, [showModal]);

  useEffect(() => {
    if (
      showModal &&
      (sanction?.type === "user_24h_blocked" ||
        sanction?.type === "user_72h_blocked")
    ) {
      getUserLastMessages(sanction?.user?.id).then((messages) => {
        if (Array.isArray(messages)) {
          setUserLastMessages(messages);
        }
      });
    }
  }, [sanction, showModal]);

  return (
    <ModalTemplate showModal={showModal} closeModal={closeModal} width="70vw">
      {!!sanction && (
        <ModalContent>
          <SanctionInfos>
            {sanction.appeals.length > 0 && (
              <div style={{ marginBottom: 24 }}>
                <Badge
                  style={{ color: "#FFF", fontSize: 24 }}
                  backgroundColor="rgba(0, 0, 255, 0.4)"
                >
                  Appealed
                </Badge>
              </div>
            )}
            <Info
              label="Type"
              value={
                mapSanctionTypeToLabel[sanction.type] || `[${sanction.type}]`
              }
              isBadge={true}
            />
            <Info label="User" value={sanction?.user?.pseudo} />
            <Info
              label="Timestamp"
              value={moment(sanction.timestamp).format("LLL")}
            />
            <Info label="Reason" value={sanction.reason} />
            <Info
              label="Status"
              value={mapSanctionStatusToLabel[sanction.status]}
              isBadge={true}
            />
          </SanctionInfos>
          {sanction?.type === "post_banned" && (
            <PostWrapper>
              <div className="title">Post banned</div>
              <PostBanned>
                {!!sanction?.bannedPost.text && (
                  <div style={{ marginBottom: 8 }} className="content">
                    <b>Content</b> : {sanction?.bannedPost.text}
                  </div>
                )}
                <div className="pictures">
                  {sanction?.bannedPost.pictures.map((picture, index) => (
                    <PostPicture key={index} picture={picture} />
                  ))}
                </div>
              </PostBanned>
            </PostWrapper>
          )}
          {sanction?.type === "live_24h_blocked" &&
            sanction?.source === "automated" && (
              <LiveWrapper>
                <Info label="Live's title" value={sanction?.bannedLive.title} />
                <Info label="Live's last thumbnail" value="" />
                {!!sanction?.bannedLive.thumbnail_path && (
                  <img
                    alt=""
                    className="moderatedPicture"
                    src={sanction?.bannedLive.thumbnail_path}
                  />
                )}
              </LiveWrapper>
            )}
          {(sanction?.type === "user_24h_blocked" ||
            sanction?.type === "user_72h_blocked") && (
            <TableWrapper>
              <div className="title">User's last messages</div>
              {userLastMessages.length > 0 ? (
                <table>
                  <thead>
                    <tr style={{ borderBottom: "2px solid #000" }}>
                      <th>Message</th>
                      <th>Timestamp</th>
                      <th>Pictures</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userLastMessages.map((message) => (
                      <TableRow key={message.id}>
                        <td>{message.content}</td>
                        <td>{moment(message.createdAt).format("LLL")}</td>
                        <td>
                          {message.pictures.map((picture) => (
                            <a
                              style={{ marginRight: 10 }}
                              target="_blank"
                              href={picture.path}
                            >
                              <ImagesIcon style={{ width: 20, height: 16 }} />
                            </a>
                          ))}
                        </td>
                      </TableRow>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Alert className="alert-warning">
                  No messages in the last 72 hours.
                </Alert>
              )}
            </TableWrapper>
          )}
          <TableWrapper>
            <div className="title">User's reports</div>
            <table>
              <thead>
                <tr style={{ borderBottom: "2px solid #000" }}>
                  <th>Source</th>
                  <th>Type</th>
                  <th>Motive</th>
                  <th>Report date</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {userReports.map((report) => (
                  <TableRow
                    key={report.id}
                    style={{ borderBottom: "1px solid #000" }}
                  >
                    <td>
                      {mapReportSourceToLabel[report.source]}{" "}
                      {report.source === "user"
                        ? `(${report.sender.pseudo})`
                        : ""}
                    </td>
                    <td>{mapReportTypeToLabel[report.type]}</td>
                    <td>{mapReportMotiveToLabel[report.motive]}</td>
                    <td>{moment(report.timestamp).format("LLL")}</td>
                    <td>{report.comment}</td>
                  </TableRow>
                ))}
              </tbody>
            </table>
          </TableWrapper>
        </ModalContent>
      )}
    </ModalTemplate>
  );
}

const Info = ({ label, value, isBadge = false }) => {
  return (
    <StyledInfo isBadge={isBadge}>
      <span className="infoLabel">{label}&nbsp;&nbsp;&nbsp;</span>
      {isBadge ? <Badge>{value}</Badge> : <span>{value}</span>}
    </StyledInfo>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 30px;
`;

const SanctionInfos = styled.div`
  margin: 15px 10px;
`;

const StyledInfo = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: ${(props) => (props.isBadge ? "center" : "flex-start")};
  font-size: 20px;
  white-space: pre-line;

  & > .infoLabel {
    font-weight: bold;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  & > .title {
    font-size: 20px;
    font-weight: bold;
  }

  & th,
  td {
    padding: 10px;
    box-sizing: border-box;
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #000;
  transition: background-color 200ms ease;

  &:hover {
    background-color: #eee;
  }
`;

const PostWrapper = styled.div`
  margin: 20px 0;

  & > .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;

const PostBanned = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 15px;
  box-sizing: border-box;

  & .content {
    font-size: 16px;
  }

  & .pictures {
    display: flex;
    flex-wrap: wrap;
  }
`;

const LiveWrapper = styled.div`
  margin: 20px 0;
  padding: 10px 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  & > .moderatedPicture {
    margin-top: 8px;
    height: 400px;
    height: 400px;
    object-fit: contain;
  }
`;
