import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { Alert, Button } from "react-bootstrap";

import ModalTemplate from "../../../components/Modal/modal-template.component";
import SaveGuideForm from "../components/save-guide.form";

import { validWebsite } from "../../../utils/regex.utils";
import { updateGuide } from "../../../services/guides.service";
import {
  mapApiTypeToLabel,
  Badge,
} from "../components/select-guides.component";

export default function CheckGuideModal({
  showModal,
  closeModal,
  displayedGuide,
  refreshDisplayedGuideData,
}) {
  const [mode, setMode] = useState("check"); // ENUM ("check" | "update")
  const [updateStatus, setUpdateStatus] = useState("idle"); // ENUM ("idle" | "loading" | "error" | "success")
  const [errorMessage, setErrorMessage] = useState("");

  /**
   * Fonction permettant de vérifier si l'input actuel est satisfaisant pour la modification d'un guide.
   */
  const guideHasErrors = (body) => {
    let errorMessage = "";
    const hasDuration =
      body.type === "event" ||
      body.type === "accommodation" ||
      body.type === "art_culture";

    if (!body.title) {
      errorMessage = "You must indicate a title.";
    }
    if (!body.description) {
      errorMessage = "You must indicate a description.";
    }
    if (!body.city) {
      errorMessage = "You must indicate a city.";
    }
    if (!body.venue) {
      errorMessage = "You must indicate a venue.";
    }
    if (!body.longitude || !body.latitude || !body.address) {
      errorMessage =
        'You must choose an autocompleted address in the "Full address" field.';
    }
    if (!body.organizer) {
      errorMessage = "You must indicate an organizer.";
    }
    if (!validWebsite(body.website)) {
      errorMessage = "You must indicate a valid website.";
    }
    if (!!body.ticket && !validWebsite(body.ticket)) {
      errorMessage = "The ticket purchase website is not a valid URL.";
    }

    if (hasDuration) {
      if (!body.startDatetime) {
        errorMessage = "You must indicate a start date & time.";
      } else if (!body.endDatetime) {
        errorMessage = "You must indicate an end date & time.";
      } else if (new Date(body.startDatetime) > new Date(body.endDatetime)) {
        errorMessage =
          "You cannot indicate a starting date older than the end date.";
      }
    }

    if (!!errorMessage) {
      setErrorMessage(errorMessage);
    }
    return !!errorMessage;
  };

  const updateGuideHandler = (body) => {
    if (updateStatus === "loading" || updateStatus === "success") {
      return;
    }

    if (guideHasErrors(body)) {
      setUpdateStatus("error");
      return;
    }

    const hasDuration =
      body.type === "event" ||
      body.type === "accommodation" ||
      body.type === "art_culture";

    const updatedBody = {};
    if (body.title !== displayedGuide.title) {
      updatedBody.title = body.title;
    }
    if (body.description !== displayedGuide.description) {
      updatedBody.description = body.description;
    }
    if (body.city !== displayedGuide.city) {
      updatedBody.city = body.city;
    }
    if (body.venue !== displayedGuide.venue) {
      updatedBody.venue = body.venue;
    }
    if (body.website !== displayedGuide.website) {
      updatedBody.website = body.website;
    }
    if (body.ticket !== displayedGuide.ticket) {
      updatedBody.ticket = body.ticket;
    }
    if (body.organizer !== displayedGuide.organizer) {
      updatedBody.organizer = body.organizer;
    }
    if (body.email !== displayedGuide.email) {
      updatedBody.email = body.email;
    }
    if (body.timeZone !== displayedGuide.time_zone) {
      updatedBody.timeZone = body.timeZone;
    }
    if (
      body.address !== displayedGuide.address &&
      body.latitude !== displayedGuide.latitude &&
      body.longitude !== displayedGuide.longitude
    ) {
      updatedBody.address = body.address;
      updatedBody.latitude = body.latitude;
      updatedBody.longitude = body.longitude;
    }

    if (hasDuration) {
      if (body.startDatetime !== displayedGuide.start_datetime) {
        updatedBody.startDatetime = body.startDatetime;
      }
      if (body.endDatetime !== displayedGuide.end_datetime) {
        updatedBody.endDatetime = body.endDatetime;
      }
    }

    if (Object.keys(updatedBody).length === 0) {
      setUpdateStatus("error");
      setErrorMessage("No values were updated");
      return;
    }

    updatedBody.adminUpdated = true;

    setUpdateStatus("loading");
    updateGuide(displayedGuide.id, updatedBody).then((res) => {
      if (res?.success) {
        setUpdateStatus("success");
        refreshDisplayedGuideData();
      } else {
        setUpdateStatus("error");
        setErrorMessage(
          "An error occured during the modification of the guide."
        );
      }
    });
  };

  useEffect(() => {
    if (showModal && !displayedGuide) {
      closeModal();
    }
  }, [showModal, displayedGuide]);

  useEffect(() => {
    if (!showModal) {
      setMode("check");
    }
  }, [showModal]);

  useEffect(() => {
    if (mode === "check") {
      setUpdateStatus("idle");
      setErrorMessage("");
    }
  }, [mode]);

  return (
    <ModalTemplate showModal={showModal} closeModal={closeModal} width="70vw">
      {!!displayedGuide && (
        <ModalContent>
          {mode === "check" && (
            <>
              <GuidePicture alt="" src={displayedGuide.picture.path} />
              <GuideInfos>
                <Info
                  label="Type"
                  value={mapApiTypeToLabel[displayedGuide.type]}
                  isBadge={true}
                />
                <Info label="Title" value={displayedGuide.title} />
                <Info
                  label="Owner"
                  value={
                    displayedGuide.admin_created
                      ? "Bearwww"
                      : displayedGuide.owner.pseudo
                  }
                />
                <Info
                  label="Creation date"
                  value={moment(displayedGuide.created_at).format("LLL")}
                />
                <Info label="Description" value={displayedGuide.description} />
                <Info label="Full address" value={displayedGuide.address} />
                <Info label="City" value={displayedGuide.city} />
                <Info label="Venue" value={displayedGuide.venue} />
                <Info label="Website" value={displayedGuide.website} />
                <Info label="Ticket website" value={displayedGuide.ticket} />
                <Info label="Organizer" value={displayedGuide.organizer} />
                <Info label="Email" value={displayedGuide.email} />
              </GuideInfos>
              <div>
                <Button
                  className="btn-fill btn-secondary"
                  onClick={() => setMode("update")}
                >
                  Update guide
                </Button>
              </div>
            </>
          )}
          {mode === "update" && (
            <UpdateWrapper>
              <SaveGuideForm
                mode="update"
                saveGuideHandler={updateGuideHandler}
                status={updateStatus}
                errorMessage={errorMessage}
                initialData={displayedGuide}
              />
              {updateStatus === "success" && (
                <Alert variant="info" className="alert-info">
                  Your guide was successfully updated !
                </Alert>
              )}
              <div>
                <Button
                  className="btn-fill btn-secondary"
                  onClick={() => setMode("check")}
                >
                  {updateStatus === "success" ? "Go back" : "Cancel"}
                </Button>
              </div>
            </UpdateWrapper>
          )}
        </ModalContent>
      )}
    </ModalTemplate>
  );
}

const Info = ({ label, value, isBadge = false }) => {
  return (
    <StyledInfo isBadge={isBadge}>
      <span className="infoLabel">{label}&nbsp;&nbsp;&nbsp;</span>
      {isBadge ? <Badge>{value}</Badge> : <span>{value}</span>}
    </StyledInfo>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  overflow-y: auto;
`;

const GuidePicture = styled.img`
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  background-color: #ddd;
`;

const GuideInfos = styled.div`
  margin: 15px 10px;
`;

const StyledInfo = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: ${(props) => (props.isBadge ? "center" : "flex-start")};
  font-size: 20px;
  white-space: pre-line;

  & > .infoLabel {
    font-weight: bold;
  }
`;

const UpdateWrapper = styled.div`
  padding: 0 10px 20px 10px;
  box-sizing: border-box;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
`;
