/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

class PagesHeader extends Component {
    constructor(props) {
        super(props);
        this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
        this.state = {
            width: window.innerWidth
        };
    }
    // function that sets the class to active of the active page
    activeRoute(routeName) {
        return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }
    // function that shows/hides sidebar on responsive
    mobileSidebarToggle(e) {
        document.documentElement.classList.toggle("nav-open");
    }
    updateWidth() {
        this.setState({ width: window.innerWidth });
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateWidth.bind(this));
    }
    render() {
        return (
            <Navbar
                collapseOnSelect
                inverse
                className="navbar-primary navbar-transparent navbar-absolute"
            >
                <Navbar.Header>
                    <Navbar.Brand>
                        <NavLink to={"/dashboard"} className="nav-link">
                            Moderation Bearwww
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={this.mobileSidebarToggle} />
                </Navbar.Header>
            </Navbar>
        );
    }
}

export default PagesHeader;
