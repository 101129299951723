import React, { useState } from "react";
import ReactTable from "react-table";
import moment from "moment";
import { Grid, Row, Col } from "react-bootstrap";
import { get } from "../../services/utils.service";
import * as queryString from 'query-string';

import Card from "components/Card/Card.jsx";

export default function ReportsScreen() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={"Reports"}
              content={
                <ReactTable
                    data={data}
                    filterable={false}
                    sortable={false}
                    columns={[
                    {
                        Header: "Motive",
                        accessor: "motive",
                    },
                    {
                        Header: "Comment",
                        accessor: "comment",
                    },
                    {
                        Header: "Report's sender",
                        id: "signaleur",
                        accessor: row => row?.sender?.pseudo || 'Hive',
                    },
                    {
                        Header: "Date",
                        id: "date",
                        accessor: row => moment(row.timestamp).format("YYYY-MM-DD HH:mm")
                    },
                    {
                        Header: "Flagged user",
                        id: "user",
                        accessor: row => row.user?.pseudo
                    },
                    {
                        Header: "Flagged post",
                        id: "post",
                        Cell: ({ value }) => value ? (<a target="__blank" href={"https://staging.bearwww.com/posts/"+value}>Voir le post</a>) : null,
                        accessor: row => row.reported_post?.slug
                    }
                    ]}
                    defaultPageSize={30}
                    showPageSizeOptions={false}
                    showPaginationTop
                    showPaginationBottom={false}
                    pages={10}
                    loading={loading}
                    className="-striped -highlight"
                    manual
                    onFetchData={async (state) => {
                        setLoading(true)
                        const json = await get("/admin/reports?" + queryString.stringify({
                            page: state.page,
                            pageSize: state.pageSize
                        }));
                        console.log("json", json)
                        setData(json)
                        setLoading(false)
                    }}
                />
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}