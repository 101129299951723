import { del, get, post } from "./utils.service";

export const createSubscription = async (body) => {
  return await post("/subscriptions", body);
};

export const getPremiumFreeCountries = async () => {
  return await get("/subscriptions/premium-free-countries");
};

export const addPremiumFreeCountry = async (countryCode) => {
  return await post("/subscriptions/premium-free-countries", {
    countryCode
  });
};

export const removePremiumFreeCountry = async (countryCode) => {
  return await del(`/subscriptions/premium-free-countries/${countryCode}`);
};
