import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Button } from "react-bootstrap";

import ModalTemplate from "../../../components/Modal/modal-template.component";

import { deleteGuide } from "../../../services/guides.service";

export default function DeleteGuideModal({
  showModal,
  closeModal,
  guideToDelete,
  removeGuideToDelete,
}) {
  const [deleteStatus, setDeleteStatus] = useState("idle"); // ENUM ("idle" | "loading" | "error" | "success")

  const deleteHandler = () => {
    if (deleteStatus === "loading") {
      return;
    }

    deleteGuide(guideToDelete.id).then((res) => {
      if (res?.success) {
        setDeleteStatus("success");
        removeGuideToDelete();
      } else {
        setDeleteStatus("error");
      }
    });
  };

  useEffect(() => {
    if (deleteStatus === "success") {
      closeModal();
    }
  }, [deleteStatus]); // eslint-disable-line

  return (
    <ModalTemplate showModal={showModal} closeModal={closeModal} width="40vw">
      {!!guideToDelete && (
        <ModalContent>
          <h2>Guide deletion</h2>
          <DisclaimerWrapper>
            <b>WARNING</b> : Deleting this guide will delete all data and this
            action won't be reversible.
          </DisclaimerWrapper>
          <ButtonWrapper>
            <Button className="btn-fill btn-danger" onClick={deleteHandler}>
              Confirm delete
            </Button>
          </ButtonWrapper>
        </ModalContent>
      )}
    </ModalTemplate>
  );
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  text-align: center;

  & > h2 {
    margin-top: 10px;
  }
`;

const DisclaimerWrapper = styled.div`
  margin: 20px 0;
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
  width: 400px;
  margin: 0 auto;
  margin-bottom: 20px;
`;
