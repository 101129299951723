import { get, post } from "./utils.service";

export const getCurrentConstants = async () => {
  return await get("/admin/accounting/current-vat-constants");
};

export const updateVatConstants = async (body) => {
  return await post("/admin/accounting/update-vat-constants", body);
};

export const getExchangeRateByMonthAndYear = async (month, year) => {
  return await get(
    `/admin/accounting/exchange-rate-constants?month=${month}&year=${year}`
  );
};

export const generateStatistics = async (body) => {
  return await post("/admin/accounting/generate-statistics", body);
};
