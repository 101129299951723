import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components/macro";

import Button from "components/CustomButton/CustomButton";

import VideoComponent from "./Video.component";
import { fetchVideos } from "services/videos.service";
import { bulkModerateVideos } from "services/videos.service";
import { deleteVideo } from "services/videos.service";

export default function Pictures() {
  const [rating, setRating] = useState(null);
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [pagesDisplayed, setPagesDisplayed] = useState(1);

  const fetchVideosHandler = async (param) => {
    const moderation = param || rating || "nonSexual";
    const pics = await fetchVideos(moderation);

    setTimeout(() => {
      setVideos(pics);
      setLoading(false);
    }, 500);
  };

  const onDone = (el, file, deleteVideo) => {
    let id = el.id;
    let moderation = el.moderation;
    let newVideos = [...videos];
    for (let i = 0; i < newVideos.length; i++) {
      if (newVideos[i].id == id) {
        newVideos[i].moderation = moderation;
        if (file) {
          newVideos[i].file = file;
        }
        if (deleteVideo === false || deleteVideo === true) {
          newVideos[i].deleteVideo = deleteVideo;
        }
      }
    }
    setVideos(newVideos);
  };

  const submit = async () => {
    setLoading(true);

    const videosToSubmit = videos.slice(0, pagesDisplayed * 20);
    const formattedVideos = videosToSubmit.map((video) => ({
      ...video,
      moderation: video.moderation || rating,
    }));

    const res = await bulkModerateVideos({
      videos: formattedVideos,
    });

    for (const formattedVideo of formattedVideos) {
      if (formattedVideo?.deleteVideo) {
        const res = await deleteVideo(formattedVideo);
        if (res?.success) {
          fetchVideosHandler(rating);
        }
      }
      if (formattedVideo.file) {
        //TODO: Handle cropped file
        // upload cropped file in the user's album
        // delete non cropped picture
        // apply moderation to cropped picture
      }
    }

    if (res?.success) {
      fetchVideosHandler(rating);
    }
  };

  useEffect(() => {
    const param = window.location.href.split("/").reverse()[0];
    setRating(param);
    fetchVideosHandler(param);
  }, []);

  return (
    <>
      <div className="main-content">
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader type="Circles" color="#9e9e9e" height={80} width={80} />
          </div>
        )}
        {!loading && videos.length > 0 && (
          <VideosWrapper>
            {videos.slice(0, pagesDisplayed * 20).map((el, index) => {
              return <VideoComponent el={el} key={index} onDone={onDone} />;
            })}
          </VideosWrapper>
        )}

        {!loading && videos.length == 0 && (
          <div>No video left to moderate</div>
        )}
        {!loading && videos.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button bsStyle="success" fill wd onClick={submit}>
              Submit
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

const VideosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
`;
