import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Loader from "react-loader-spinner";
import {
  Alert,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";
import Select from "../../../components/Form/select.input";

import { createSanction } from "services/sanctions.service";

export default function CreateSanction() {
  const [status, setStatus] = useState("idle"); // ENUM ("idle" | "loading" | "error" | "success")
  const [errorMessage, setErrorMessage] = useState("");

  const [type, setType] = useState("warning");
  const [reason, setReason] = useState("");
  const [pseudo, setPseudo] = useState("");

  const submitSanctionHandler = () => {
    if (status === "loading") {
      return;
    }
    setStatus("loading");
    const body = {
      type,
      reason: reason.trim(),
      pseudo: pseudo.trim(),
    };
    createSanction(body).then((res) => {
      if (res?.success) {
        setStatus("success");
      } else {
        setErrorMessage(
          res.error ||
            "An error occured during the creation of your sanction, please try again."
        );
        setStatus("error");
      }
    });
  };

  return (
    <Form horizontal>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Type</ControlLabel>
          <Col sm={10}>
            <Select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="warning">Warning</option>
              <option value="user_24h_blocked">User block 24h</option>
              <option value="user_72h_blocked">User block 72h</option>
              <option value="user_banned">User ban</option>
              <option value="live_24h_blocked">Live block 24h</option>
            </Select>
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">Reason</ControlLabel>
          <Col sm={10}>
            <FormControl
              type="text"
              placeholder="Reason"
              name="reason"
              onChange={(e) => setReason(e.target.value)}
              value={reason}
            />
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup>
          <ControlLabel className="col-sm-2">User</ControlLabel>
          <Col sm={10}>
            <Autocomplete>
              <AutocompleteInput
                type="text"
                placeholder="User"
                name="user"
                onChange={(e) => setPseudo(e.target.value)}
                value={pseudo}
                autoComplete="off"
              />
            </Autocomplete>
          </Col>
        </FormGroup>
      </fieldset>
      <fieldset>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button onClick={submitSanctionHandler}>Create</Button>
          {status === "loading" && (
            <div style={{ marginleft: 8 }}>
              <Loader />
            </div>
          )}
        </div>
      </fieldset>
      {status === "error" && (
        <MarginWrapper>
          <Alert className="alert-danger">{errorMessage}</Alert>
        </MarginWrapper>
      )}
      {status === "success" && (
        <MarginWrapper>
          <Alert className="alert-info">
            Your sanction has been successfully created !
          </Alert>
        </MarginWrapper>
      )}
    </Form>
  );
}

const MarginWrapper = styled.div`
  margin: 8px 0;
`;

const Autocomplete = styled.div`
  position: relative;
`;

const AutocompleteInput = styled.input`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
`;
