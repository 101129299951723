/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

export class UserCard extends Component {
    render() {
        return (
            <div className="card card-user">
                <div className="image">
                    <img src={this.props.bgImage} alt="..." />
                </div>
                <div className="content">
                    <div className="author">
                        <img
                            className="avatar border-gray"
                            src={this.props.avatar}
                            alt="..."
                        />
                        <h4 className="title">
                            {this.props.name}
                            <br />
                            <small>{this.props.userName}</small>
                        </h4>
                    </div>
                    <p className="description text-center">
                        {this.props.description}
                    </p>
                </div>
                <hr />
                <div className="text-center">{this.props.socials}</div>
            </div>
        );
    }
}

export default UserCard;
