import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components/macro";

import PictureComponent from "./Components/Picture.component";
import Button from "components/CustomButton/CustomButton";

import {
  // moderatePicture,
  fetchPictures,
  bulkModeratePictures,
} from "services/pictures.service";
import { deletePicture } from "services/pictures.service";

export default function Pictures() {
  const [rating, setRating] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [pagesDisplayed, setPagesDisplayed] = useState(1);

  const fetchPicturesHandler = async (param) => {
    const moderation = param || rating || "nonSexual";
    const pics = await fetchPictures(moderation);

    setTimeout(() => {
      setPictures(pics);
      setLoading(false);
    }, 500);
  };

  const onDone = (el, file, deletePicture) => {
    let id = el.id;
    let moderation = el.moderation;
    let newPictures = [...pictures];
    for (let i = 0; i < newPictures.length; i++) {
      if (newPictures[i].id == id) {
        newPictures[i].moderation = moderation;
        if (file) {
          newPictures[i].file = file;
        }
        if (deletePicture === false || deletePicture === true) {
          newPictures[i].deletePicture = deletePicture;
        }
      }
    }
    setPictures(newPictures);
  };

  const submit = async () => {
    setLoading(true);

    const picturesToSubmit = pictures.slice(0, pagesDisplayed * 20);
    const formattedPictures = picturesToSubmit.map((picture) => ({
      ...picture,
      moderation: picture.moderation || rating,
    }));

    const res = await bulkModeratePictures({
      pictures: formattedPictures,
    });

    for (const formattedPicture of formattedPictures) {
      console.log("formattedPicture", formattedPicture);
      if (formattedPicture?.deletePicture) {
        const res = await deletePicture(formattedPicture);
        if (res?.success) {
          fetchPicturesHandler(rating);
        }
      }
      if (formattedPicture.file) {
        //TODO: Handle cropped file
        // upload cropped file in the user's album
        // delete non cropped picture
        // apply moderation to cropped picture
      }
    }

    if (res?.success) {
      fetchPicturesHandler(rating);
    }
  };

  useEffect(() => {
    const param = window.location.href.split("/").reverse()[0];
    setRating(param);
    fetchPicturesHandler(param);
  }, []);

  return (
    <>
      <div className="main-content">
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader type="Circles" color="#9e9e9e" height={80} width={80} />
          </div>
        )}
        {!loading && pictures.length > 0 && (
          <PicturesWrapper>
            {pictures.slice(0, pagesDisplayed * 20).map((el, index) => {
              return <PictureComponent el={el} key={index} onDone={onDone} />;
            })}
          </PicturesWrapper>
        )}

        {!loading && pictures.length == 0 && (
          <div>No picture left to moderate</div>
        )}
        {!loading && pictures.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button bsStyle="success" fill wd onClick={submit}>
              Submit
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

const PicturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
`;
