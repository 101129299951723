import React, { useState } from "react";
import styled from "styled-components/macro";
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import GeneratePromotionalCodes from "./components/generate-codes.component";
import GivePremium from "./components/give-premium.component";
import PremiumFreeCountries from "./components/premium-free-countries.component";

const mapModeToTitle = {
  give_premium: "Give premium",
  generate_codes: "Generate promotional codes",
  premium_free_countries: "Premium-free countries",
};

const mapModeToComponent = {
  give_premium: <GivePremium />,
  generate_codes: <GeneratePromotionalCodes />,
  premium_free_countries: <PremiumFreeCountries />,
};

export default function SubscriptionsScreen() {
  const [mode, setMode] = useState("give_premium"); // "give_premium" | "generate_codes" | "premium_free_countries"
  return (
    <div className="main-content">
      <ModeSelector>
        <Mode
          active={mode === "give_premium"}
          onClick={() => setMode("give_premium")}
        >
          Give premium
        </Mode>
        <Mode
          active={mode === "generate_codes"}
          onClick={() => setMode("generate_codes")}
        >
          Generate promotional codes
        </Mode>
        <Mode
          active={mode === "premium_free_countries"}
          onClick={() => setMode("premium_free_countries")}
        >
          Premium-free countries
        </Mode>
      </ModeSelector>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={mapModeToTitle[mode]}
              content={mapModeToComponent[mode]}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

const ModeSelector = styled.div`
  margin-bottom: 12px;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const Mode = styled.div`
  transition: all 200ms ease;
  cursor: pointer;
  user-select: none;
  padding: 8px 24px;
  margin-right: 8px;
  font-size: 14px;
  border-bottom: 2px solid
    ${(props) => (props.active ? "#cecece" : "transparent")};

  &:hover {
    background-color: #cecece;
  }
`;
