/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Grid,
  Row,
  Col,
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import UserCard from "components/Card/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { get, post } from "services/utils.service";
import { moderatePicture } from "services/pictures.service";
import defaultAvatar from "assets/img/default_profile_picture.png";
import moment from "moment";

import PictureComponent from "../Pictures/Components/Picture.component";
import styled from "styled-components/macro";

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const SexualPracticeWrapper = styled(RowWrapper)`
  color: red;
`;

const CommunityWrapper = styled(RowWrapper)`
  color: #ba68c8;
`;

const EthnicityWrapper = styled(RowWrapper)`
  color: #64b5f6;
`;

const FacialHairWrapper = styled(RowWrapper)`
  color: #9e9e9e;
`;

const Item = styled.div`
  margin: 2px;
`;

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      user: {},
      pictures: [],
    };
  }
  componentDidMount() {
    this.fetchUser();
  }

  fetchUser = async () => {
    const json = await get(`/admin/user/${this.props.id}`);
    this.setState({ user: json.user, pictures: json.pictures });
  };

  updateDescription = async (description) => {
    await post(`/admin/user/${this.props.id}/description`, {
      description,
    });
  };

  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={8} style={{ display: "flex", flexWrap: "wrap" }}>
              {this.state.pictures.map((picture) => (
                <PictureComponent
                  el={picture}
                  onDone={(picture) => {
                    moderatePicture({
                      id: picture.id,
                      moderation: picture.moderation,
                      path: picture.path,
                    });
                  }}
                />
              ))}
            </Col>
            <Col md={4}>
              <UserCard
                bgImage="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
                avatar={this.state.user.profilePicture?.path ?? defaultAvatar}
                name={this.state.user.email}
                userName={`${this.state.user.pseudo} ${
                  this.state.user.weight ? `| ${this.state.user.weight}kg` : ""
                }`}
                description={
                  <div>
                    <b>IP:</b> {this.state.user.last_ip}
                    <br />
                    <b>ID:</b> {this.state.user.id}
                    <br />
                    <b>Abonné:</b>{" "}
                    {this.state.user.role === "premium" ? "Oui" : "Non"}
                    <br />
                    <FormControl
                      rows="5"
                      componentClass="textarea"
                      bsClass="form-control"
                      placeholder="Description..."
                      defaultValue={this.state.user.description}
                      onChange={(e) => this.updateDescription(e.target.value)}
                    />
                    <SexualPracticeWrapper>
                      <i className="fa fa-scissors" />
                      {this.state.user.sexualPractices &&
                        this.state.user.sexualPractices.map((el) => {
                          return <Item>{el.sexualPracticeKey}</Item>;
                        })}
                    </SexualPracticeWrapper>
                    <CommunityWrapper>
                      <i className="fa fa-heart" />
                      {this.state.user.communitiesLike &&
                        this.state.user.communitiesLike.map((el) => {
                          return <Item>{el.communityKey}</Item>;
                        })}
                    </CommunityWrapper>
                    <EthnicityWrapper>
                      <i className="fa fa-map" />
                      <Item>
                        {this.state.user.ethnicity &&
                          this.state.user.ethnicity.ethnicityKey}
                      </Item>
                    </EthnicityWrapper>
                    <FacialHairWrapper>
                      <i className="fa fa-user-secret" />
                      <Item>
                        {this.state.user.facialHair &&
                          this.state.user.facialHair.facialHairKey}
                      </Item>
                    </FacialHairWrapper>
                  </div>
                }
                socials={
                  <Row
                    style={{
                      padding: 5,
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <i className="fa fa-file-video-o" />
                    {this.state.user.videoTitle1 && (
                      <a href={this.state.user.videoLink1}>
                        {this.state.user.videoTitle1}
                      </a>
                    )}
                    {this.state.user.videoTitle2 && (
                      <a href={this.state.user.videoLink2}>
                        {this.state.user.videoTitle2}
                      </a>
                    )}
                  </Row>
                }
              />

              <div>
                <b>Sanctions</b>
                {this.state.user?.sanctions?.length > 0 ? (
                  <table className="smallTable">
                    <tr>
                      <td>Source</td>
                      <td>Type</td>
                      <td>Reason</td>
                      <td>Date</td>
                    </tr>
                    {this.state.user?.sanctions?.map((sanction) => (
                      <tr>
                        <td>{sanction.source}</td>
                        <td>{sanction.type}</td>
                        <td>{sanction.reason}</td>
                        <td>{moment(sanction.timestamp).format()}</td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <div className="noData">Aucun</div>
                )}
              </div>
              <div>
                <b>Abonnements</b>
                {this.state.user?.subscriptions?.length > 0 ? (
                  <table className="smallTable">
                    <tr>
                      <td>Start date</td>
                      <td>End date</td>
                      <td>Source</td>
                      <td>Type</td>
                    </tr>
                    {this.state.user?.subscriptions?.map((subscription) => (
                      <tr>
                        <td>
                          {moment(subscription.start_date).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        <td>
                          {moment(subscription.end_date).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </td>
                        <td>{subscription.source}</td>
                        <td>{subscription.duration}</td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <div className="noData">Aucun</div>
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserPage;
