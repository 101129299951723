import React, { Component, useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import VoteCard from "./components/votecard.component";
import { get, post } from "../../services/utils.service";

const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const CommunityRow = (props) => {
  const ref = useRef();
  return (
    <Column
      key={props.title}
      ref={ref}
      style={{ borderBottom: "1px solid grey", marginTop: 5 }}
    >
      <Title>{props.title}</Title>
      <Row>{props.children}</Row>
    </Column>
  );
};

const GuysScreen = (props) => {
  const [votes, setVotes] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [guys, setGuys] = useState([]);
  const [nextWeekGuys, setNextWeekGuys] = useState([]);
  useEffect(() => {
    getCurrentVotes();
  }, []);
  const getCurrentVotes = async () => {
    let res = await get("/admin/votes");
    if (res.votes) {
      setVotes(res.votes);
    }
    if (res.communities) {
      setCommunities(res.communities);
    }
    if (res.guys) {
      setGuys(res.guys);
    }
    if (res.nextWeekGuys) {
      setNextWeekGuys(res.nextWeekGuys);
    }
  };

  const confirm = async (user_id, community_id) => {
    let res = await post("/admin/votes", {
      user_id,
      community_id,
    });
    if (res.guys) {
      setNextWeekGuys(res.guys);
    }
  };
  return (
    <Wrapper>
      {communities.map((el) => {
        return (
          <CommunityRow title={el.communityKey}>
            {votes
              .filter((vote) => vote.community_id == el.id)
              .map((vote) => {
                return (
                  <VoteCard
                    profilePicture_path={vote.profilePicture_path}
                    user_pseudo={vote.user_pseudo}
                    count={vote.count}
                    current={
                      guys.find((el) => el.user.id == vote.vote_user_id)
                        ? true
                        : false
                    }
                    next={
                      nextWeekGuys.find((el) => el.user.id == vote.vote_user_id)
                        ? true
                        : false
                    }
                    confirm={() =>
                      confirm(vote.vote_user_id, vote.community_id)
                    }
                  />
                );
              })}
          </CommunityRow>
        );
      })}
    </Wrapper>
  );
};

export default GuysScreen;
