import { get } from "./utils.service";

export const getUserConversations = async (pseudo, page, limit) => {
  return await get(`/admin/user-conversations?page=${page}&limit=${limit}&pseudo=${pseudo}`);
};

export const getConversationMessages = async (conversationId, pseudo, page) => {
  return await get(
    `/admin/conversation-messages/${conversationId}?pseudo=${pseudo}&page=${page}`
  );
};
