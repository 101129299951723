import React, { useState } from "react";
import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  ControlLabel,
  Col,
} from "react-bootstrap";
import styled from "styled-components/macro";
import moment from "moment";
import Loader from "react-loader-spinner";

import Select from "components/Form/select.input";
import { generatePromotionalCodes } from "services/promotional-codes.service";

export default function GeneratePromotionalCodes() {
  const [status, setStatus] = useState("idle"); // ENUM (idle | loading | error | success)
  const [errorMessage, setErrorMessage] = useState("");

  const [codesGenerated, setCodesGenerated] = useState([]);

  const [amount, setAmount] = useState(10);
  const [duration, setDuration] = useState("1WEEK"); // ENUM (1WEEK | 1MONTH | 3MONTHS | 6MONTHS | 1YEAR)
  const [startValidityDate, setStartValidityDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [endValidityDate, setEndValidityDate] = useState(
    moment().add(10, "days").format("YYYY-MM-DD")
  );

  const generateCodesHandler = () => {
    if (status === "loading" || status === "success") {
      return;
    }

    if (+amount <= 0) {
      setErrorMessage("Please add a positive amount of generated");
      setStatus("error");
      return;
    }

    if (+amount > 9999) {
      setErrorMessage(
        "You can generate up to 9999 codes at the same time, not more."
      );
      setStatus("error");
      return;
    }

    const formData = {
      amount: +amount,
      duration,
      startValidityDate: moment(startValidityDate, "YYYY-MM-DD")
        .set("hour", 2)
        .set("second", 1)
        .format(),
      endValidityDate: moment(endValidityDate, "YYYY-MM-DD")
        .set("hour", 2)
        .set("second", 1)
        .format(),
    };

    setErrorMessage("");
    setStatus("loading");
    generatePromotionalCodes(formData).then((res) => {
      if (res?.success) {
        setStatus("success");
        setCodesGenerated(res.codes);
      } else {
        setStatus("error");
        setErrorMessage(
          "Something went wrong while generating promotional codes, please try again later or contact technical support."
        );
      }
    });
  };

  const downloadCodesHandler = () => {
    if (codesGenerated.length === 0) {
      return;
    }

    const text = codesGenerated.reduce((acc, curr) => acc + `${curr}\n`, "");
    const data = new Blob([text], { type: "text/plain;charset=utf-8" });
    const textFileUrl = URL.createObjectURL(data);

    const link = document.createElement("a");
    link.href = textFileUrl;
    link.download = `promo_codes_${moment().format("DD-MM-YYYY")}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Wrapper>
      {status !== "success" ? (
        <FormWrapper>
          <fieldset>
            <FormGroup>
              <ControlLabel className="col-sm-2">Amount</ControlLabel>
              <Col sm={10}>
                <FormControl
                  placeholder="Amount"
                  type="number"
                  name="amount"
                  onChange={(e) => {
                    if (!isNaN(Math.round(e.target.value))) {
                      setAmount(Math.round(e.target.value));
                    }
                  }}
                  value={amount}
                />
              </Col>
            </FormGroup>
          </fieldset>
          <fieldset>
            <FormGroup>
              <ControlLabel className="col-sm-2">Duration</ControlLabel>
              <Col sm={10}>
                <Select
                  value={duration}
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                >
                  <option value="1WEEK">1 week</option>
                  <option value="1MONTH">1 month</option>
                  <option value="3MONTHS">3 months</option>
                  <option value="6MONTHS">6 months</option>
                  <option value="1YEAR">1 year</option>
                </Select>
              </Col>
            </FormGroup>
          </fieldset>
          <fieldset>
            <FormGroup>
              <ControlLabel className="col-sm-2">
                Start validity date
              </ControlLabel>
              <Col sm={10}>
                <FormControl
                  placeholder="Start validity date"
                  type="date"
                  name="start_validity_date"
                  onChange={(e) => setStartValidityDate(e.target.value)}
                  value={startValidityDate}
                />
              </Col>
            </FormGroup>
          </fieldset>
          <fieldset>
            <FormGroup>
              <ControlLabel className="col-sm-2">
                End validity date
              </ControlLabel>
              <Col sm={10}>
                <FormControl
                  placeholder="End validity date"
                  type="date"
                  name="end_validity_date"
                  onChange={(e) => setEndValidityDate(e.target.value)}
                  value={endValidityDate}
                />
              </Col>
            </FormGroup>
          </fieldset>
          {status === "error" && (
            <fieldset>
              <Alert variant="danger" className="alert-danger">
                {errorMessage}
              </Alert>
            </fieldset>
          )}
          <fieldset>
            {status === "loading" ? (
              <Loader type="Circles" color="#9e9e9e" height={25} width={25} />
            ) : (
              <Button onClick={generateCodesHandler}>Generate codes</Button>
            )}
          </fieldset>
        </FormWrapper>
      ) : (
        <SuccessWrapper>
          <Alert variant="info" className="alert-info">
            You successfully generated promotional codes.
          </Alert>
          <div>
            <Button onClick={downloadCodesHandler}>Download codes</Button>
          </div>
          <CodesWrapper>
            {codesGenerated.map((code) => (
              <div>{code}</div>
            ))}
          </CodesWrapper>
        </SuccessWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 16px;
  }
`;

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 24px;
  }
`;

const CodesWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    width: 45%;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 600;
  }
`;
