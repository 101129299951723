export const canAccessAccounting = () => {
  const token = localStorage.getItem("tokenModeration");
  const userData = token.split(".")[1] || "";

  if (!userData) {
    return false;
  }

  try {
    const user = JSON.parse(atob(userData));
    return [
      "40540f78-a8c3-4415-8e75-93c90841a467", // Pseudo : TheManager
    ].includes(user.id);
  } catch {
    return false;
  }
};
