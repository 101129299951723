/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import styled from "styled-components/macro";
import Button from "components/CustomButton/CustomButton.jsx";
import { post } from "services/utils.service";

const ErrorWrapper = styled.div`
  color: red;
`;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      pseudo: null,
      password: null,
      error: "",
    };
  }
  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }

  login = async () => {
    const defaultErrorMessage =
      "An error occured during the attempt to login. Please try again.";
    const res = await post("/auth/signin", {
      pseudo: this.state.pseudo,
      password: this.state.password,
    });
    try {
      if (!!res?.jwt) {
        const jwtArray = res.jwt.split(".");
        const jwtBody = jwtArray[1];
        const userData = JSON.parse(atob(jwtBody));
        if (userData.role !== "admin") {
          this.setState({
            error:
              "You do not possess the sufficient permissions to access the moderation tools.",
          });
        } else {
          localStorage.setItem("tokenModeration", res.jwt);
          window.location.reload();
        }
      } else {
        this.setState({
          error: defaultErrorMessage,
        });
      }
    } catch (e) {
      this.setState({
        error: defaultErrorMessage,
      });
    }
  };

  render() {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Pseudo</ControlLabel>
                      <FormControl
                        placeholder="Enter pseudo"
                        type="text"
                        onChange={(e) =>
                          this.setState({
                            pseudo: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        onChange={(e) =>
                          this.setState({
                            password: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </div>
                }
                legend={
                  <Button bsStyle="info" fill wd onClick={this.login}>
                    Login
                  </Button>
                }
                ftTextCenter
              />
              <ErrorWrapper>{this.state.error}</ErrorWrapper>
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;
