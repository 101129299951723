import React from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components/macro";

export default function DisplayStatistics({
  statistics,
  currency,
  exchangeRateConstant,
  periodLabel,
}) {
  const hasData = statistics?.perCountry.length > 0;
  return (
    <StatisticsWrapper>
      {hasData ? (
        <>
          <StatisticsTitle>
            Subscriptions statistics - {periodLabel} - <mark>{currency}</mark>
          </StatisticsTitle>
          {!!statistics?.perCountry?.length > 0 && (
            <div>
              <TableTitle>
                List per country : number, VAT-free turnover, VAT, total
                turnover (
                <mark>
                  <b>{currency}</b>
                </mark>
                )
              </TableTitle>
              <StyledTable>
                <thead>
                  <tr>
                    <th>Country</th>
                    <th></th>
                    <th>Count</th>
                    <th>VAT-free turnover</th>
                    <th>VAT</th>
                    <th>%</th>
                    <th>Total turnover</th>
                  </tr>
                </thead>
                <tbody>
                  {statistics.perCountry.map((country) => (
                    <tr key={country.countryCode}>
                      <td>{country.countryLabel}</td>
                      <td>{country.countryCode}</td>
                      <td>{country.count}</td>
                      <td>{country.amountWithoutVat}</td>
                      <td>{country.vatAmount}</td>
                      <td>({country.vatPercentage}%)</td>
                      <td>{country.amountIncludingVat}</td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          )}
          {!!statistics?.vatPerEuCountry?.length > 0 && (
            <div>
              <TableTitle>VAT summary per EU countries</TableTitle>
              <StyledTable>
                <tbody>
                  {statistics.vatPerEuCountry.map((euCountry) => (
                    <tr key={euCountry.countryCode}>
                      <td>
                        <b>{euCountry.countryCode}</b>
                      </td>
                      <td>{euCountry.countryLabel}</td>
                      <td>({euCountry.vatPercentage}%)</td>
                      <td>
                        {euCountry.vatAmount} {currency}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          )}
          {!!statistics?.accountingSummary?.length > 0 && (
            <div>
              <TableTitle>
                Accounting summary (in <mark>EUR</mark>,{" "}
                <mark>change = {exchangeRateConstant[currency]}</mark>)
              </TableTitle>
              <StyledTable hasBorders>
                <tbody>
                  {statistics.accountingSummary.map((row) => (
                    <tr key={row.accountingCode}>
                      <td style={{ textAlign: "left" }}>
                        {row.accountingCode}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {row.descriptionLabel}
                      </td>
                      <td>{row.leftValue || ""}</td>
                      <td>{row.rightValue || ""}</td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          )}
        </>
      ) : (
        <Alert className="alert-info">
          No subscriptions were registered for this period of time.
        </Alert>
      )}
    </StatisticsWrapper>
  );
}

const StatisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 20px;
  }
`;

const StatisticsTitle = styled.div`
  font-size: 30px;
  font-weight: 800;
  color: red;
`;

const TableTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  font-size: 16px;

  & th,
  td {
    text-align: right;
    padding: 10px;
    box-sizing: border-box;
  }

  ${(p) =>
    !!p.hasBorders &&
    `
    & td {
      border-right: 1px solid #888;
    }

    & td:last-child {
      border-right: none;
    }
  `}
`;
