import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { Alert, Button } from "react-bootstrap";
import Switch from "react-switch";
import Loader from "react-loader-spinner";

import Select from "components/Form/select.input";
import CheckSanctionModal from "../modals/check-sanction.modal";

import { getSanctions, moderateSanction } from "services/sanctions.service";

export const mapSanctionTypeToLabel = {
  warning: "Warning",
  user_24h_blocked: "24-hour block",
  user_72h_blocked: "72-hour block",
  user_banned: "User banned",
  post_banned: "Post banned",
  live_24h_blocked: "24-hour live block",
};

export const mapSanctionStatusToLabel = {
  validated: "Validated",
  canceled: "Canceled",
  pardonned: "Pardonned",
};

let refreshTimeout = null;

// DEBUG: it'll be necessary to add a page system on the data fetching too
export default function SelectSanctions() {
  const [sanctions, setSanctions] = useState([]);
  const [pagesDisplayed, setPagesDisplayed] = useState(1);
  const [selectMode, setSelectMode] = useState("pending"); // ENUM (pending | moderated)
  const [fetchingStatus, setFetchingStatus] = useState("idle"); // ENUM (idle | loading)
  const [updateStatus, setUpdateStatus] = useState("idle"); // ENUM (idle | loading)

  const [sanctionSelected, setSanctionSelected] = useState(null);
  const [showSanctionModal, setShowSanctionModal] = useState(false);

  const selectSanction = (sanction) => {
    setSanctionSelected(sanction);
    setShowSanctionModal(true);
  };

  const updateSanction = (sanctionId, newStatus) => {
    if (updateStatus === "loading") {
      return;
    }

    setUpdateStatus("loading");
    const body = {
      sanctionId,
      status: newStatus,
    };
    moderateSanction(body).then((res) => {
      setUpdateStatus("idle");
      if (res?.success) {
        if (selectMode === "pending") {
          setSanctions((prevSanctions) =>
            prevSanctions.filter((sanction) => sanction.id !== sanctionId)
          );
        } else if (selectMode === "moderated") {
          const copiedSanctions = JSON.parse(JSON.stringify(sanctions));
          const modifiedSanction = copiedSanctions.find(
            (_sanction) => _sanction.id === sanctionId
          );
          modifiedSanction.status = newStatus;
          setSanctions(copiedSanctions);
        }
      }
    });
  };

  useEffect(() => {
    const refreshSanctions = () => {
      setSanctions([]);
      setFetchingStatus("loading");
      getSanctions(selectMode).then((sanctions) => {
        if (Array.isArray(sanctions)) {
          refreshTimeout = setTimeout(() => {
            setFetchingStatus("idle");
            setSanctions(sanctions);
          }, 500);
        } else {
          setFetchingStatus("idle");
        }
      });
    };
    refreshSanctions();

    return () => clearTimeout(refreshTimeout);
  }, [selectMode]);

  return (
    <Wrapper>
      <Actions>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: 18, marginRight: 8 }}>Pending</span>
          <Switch
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={40}
            disabled={fetchingStatus === "loading"}
            checked={selectMode === "moderated"}
            onChange={(checked) => {
              if (fetchingStatus === "idle") {
                if (checked) {
                  setSelectMode("moderated");
                } else {
                  setSelectMode("pending");
                }
              }
            }}
          />
          <span style={{ fontSize: 18, marginLeft: 8 }}>Moderated</span>
        </div>
      </Actions>
      <TableWrapper>
        {sanctions.length > 0 ? (
          <table>
            <thead>
              <tr>
                <HeaderCell>Type</HeaderCell>
                <HeaderCell>User</HeaderCell>
                <HeaderCell>Timestamp</HeaderCell>
                <HeaderCell>Status</HeaderCell>
                <HeaderCell></HeaderCell>
                <HeaderCell></HeaderCell>
              </tr>
            </thead>
            <tbody>
              {sanctions.slice(0, pagesDisplayed * 10).map((sanction) => (
                <Row
                  key={sanction.id}
                  style={{
                    pointerEvents: updateStatus === "loading" ? "none" : "auto",
                  }}
                  onClick={() => selectSanction(sanction)}
                >
                  <Cell>
                    <Badge>
                      {mapSanctionTypeToLabel[sanction.type] ||
                        `[${sanction.type}]`}
                    </Badge>
                  </Cell>
                  <Cell>{sanction?.user?.pseudo}</Cell>
                  <Cell>{moment(sanction.timestamp).format("LLL")}</Cell>
                  <Cell>
                    <Badge>{mapSanctionStatusToLabel[sanction.status]}</Badge>
                  </Cell>
                  <Cell>
                    {sanction.appeals.length > 0 && (
                      <Badge
                        style={{ color: "#FFF" }}
                        backgroundColor="rgba(0, 0, 255, 0.4)"
                      >
                        Appealed
                      </Badge>
                    )}
                  </Cell>
                  <Cell>
                    <Select
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        updateSanction(sanction.id, e.target.value);
                      }}
                      value={selectMode === "pending" ? "" : sanction.status}
                    >
                      {selectMode === "pending" && (
                        <option value="">---</option>
                      )}
                      <option value="validated">Validated</option>
                      <option value="pardonned">Pardonned</option>
                      <option value="canceled">Canceled</option>
                    </Select>
                  </Cell>
                </Row>
              ))}
            </tbody>
          </table>
        ) : (
          <>
            {fetchingStatus === "loading" && <Loader type="TailSpin" />}
            <CustomAlert
              className="alert-info"
              opacity={fetchingStatus === "loading" ? 0 : 1}
            >
              No guides available for this mode.
            </CustomAlert>
          </>
        )}
        {sanctions.length > 10 * pagesDisplayed && (
          <span style={{ margin: "20px 0", width: 200 }}>
            <Button
              className="btn-fill btn-info"
              onClick={() => setPagesDisplayed((prevState) => prevState + 1)}
            >
              See more
            </Button>
          </span>
        )}
      </TableWrapper>
      <CheckSanctionModal
        showModal={showSanctionModal}
        closeModal={() => setShowSanctionModal(false)}
        sanction={sanctionSelected}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 8px;
  }
`;

const TableWrapper = styled.div`
  width: 100%;

  & > table {
    width: 100%;
  }
`;

const Row = styled.tr`
  user-select: none;
  cursor: pointer;
  transition: all 200ms ease;
  height: 58px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const HeaderCell = styled.th`
  padding: 10px 5px;
`;

const Cell = styled.td`
  padding: 10px 5px;
`;

export const Badge = styled.span`
  padding: 6px 18px;
  background-color: ${(p) => p.backgroundColor || "#bbb"};
  border-radius: 4px;
`;

export const CustomAlert = styled(Alert)`
  opacity: ${(p) => p.opacity || "0"};
  transition: opacity 400ms ease;
`;
