import { get, post } from "./utils.service";

export const getLiveStreams = async () => {
  return await get("/live");
};

export const adminStopLive = async (streamName) => {
  return await post(`/live/${streamName}/stop`, {
    adminStop: true,
  });
};
