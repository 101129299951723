import React, { Component, useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import Button from "components/CustomButton/CustomButton.jsx";
const Wrapper = styled.div`
  height: 300px;
  width: 200px;
  background-color: white;
  position: relative;
  border-radius: 4px;

  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Image = styled.img`
  height: 50%;
  width: 100%;
  border-radius: 4px;
  object-fit: contain;
`;

const Pseudo = styled.div``;

const Star = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: red;
`;

const AffectButton = styled.div``;

const VoteCard = (props) => {
  return (
    <Wrapper style={{ border: props.next ? "2px solid green" : "" }}>
      <Image src={props.profilePicture_path} />{" "}
      <Pseudo>
        {props.user_pseudo} - {props.count} vote(s)
      </Pseudo>
      {props.current && <Star className="fa fa-star" />}
      <Button
        bsStyle={"success"}
        fill
        wd
        style={{
          width: 100,
          marginTop: 10,
        }}
        onClick={props.confirm}
      >
        Confirm
      </Button>
    </Wrapper>
  );
};

export default VoteCard;
