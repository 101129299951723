import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";
// react component used to create charts
// import ChartistGraph from "react-chartist";
// react components used to create a SVG / Vector map
import { VectorMap } from "react-jvectormap";

import Card from "components/Card/Card.jsx";
// import StatsCard from "components/Card/StatsCard.jsx";
// import Tasks from "components/Tasks/Tasks.jsx";

// import {
//     dataPie,
//     dataSales,
//     optionsSales,
//     responsiveSales,
//     dataBar,
//     optionsBar,
//     responsiveBar
// } from "variables/Variables.jsx";
import { get } from "services/utils.service";

const fr_flag = require("../assets/img/flags/FR.png");

// const table_data = [{ flag: fr_flag, country: "France", count: "1.300" }];

// var mapData = {
//     FR: 1300
// };

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: [{ flag: fr_flag, country: "France", count: 0 }],
      mapData: {
        FR: 0,
      },
    };
  }
  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = async () => {
    /*
    const users = await get("/admin/users", {});
    let count = users.length;
    this.setState({
      table_data: [{ flag: fr_flag, country: "France", count: count }],
      mapData: {
        FR: count,
      },
    });
    */
  };
  createTableData() {
    var tableRows = [];
    for (var i = 0; i < this.state.table_data.length; i++) {
      tableRows.push(
        <tr key={i}>
          <td>
            <div className="flag">
              <img src={this.state.table_data[i].flag} alt="us_flag" />
            </div>
          </td>
          <td>{this.state.table_data[i].country}</td>
          <td className="text-right">{this.state.table_data[i].count}</td>
          <td className="text-right">{this.state.table_data[i].percentage}</td>
        </tr>
      );
    }
    return tableRows;
  }
  render() {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Global Overview by Top Locations"
                category="All users registered"
                content={
                  <Row>
                    <Col md={5}>
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>{this.createTableData()}</tbody>
                        </table>
                      </div>
                    </Col>
                    <Col md={6} mdOffset={1}>
                      <VectorMap
                        map={"world_mill"}
                        backgroundColor="transparent"
                        zoomOnScroll={false}
                        containerStyle={{
                          width: "100%",
                          height: "280px",
                        }}
                        containerClassName="map"
                        regionStyle={{
                          initial: {
                            fill: "#e4e4e4",
                            "fill-opacity": 0.9,
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0,
                          },
                        }}
                        series={{
                          regions: [
                            {
                              values: this.state.mapData,
                              scale: ["#AAAAAA", "#444444"],
                              normalizeFunction: "polynomial",
                            },
                          ],
                        }}
                      />
                    </Col>
                  </Row>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
