import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Grid, Col, Row } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import Heading from "@tiptap/extension-heading";
import { useState } from "react";
import { get, post } from "services/utils.service";
import UsersTable from "views/Users/Users";
import "./../../App.css";

Link.extend({
  inclusive: true,
});

const uploadFileToS3 = async (file) => {
  const { url } = await get(`/s3/bucket/url?type=${file.type}`);

  const headersContent = {
    "Content-Type": file.type,
    Accept: "application/json",
  };
  const headers = new Headers(headersContent);
  const response = await fetch(url, {
    method: "PUT",
    body: file,
    headers,
  });

  if (!response) {
    return null;
  }

  const resUrl = new URL(response.url);

  return {
    path: resUrl.protocol + "//" + resUrl.host + resUrl.pathname,
  };
};

const Editor = ({ onSend, onReset }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      Image,
      Heading.configure({
        levels: [1, 2, 3, 4, 5, 6],
      }),
    ],
    content: ``,
  });

  const [headingSize, setHeadingSize] = useState("normal");

  const resetEditor = () => {
    editor.commands.setContent("");
  };

  const applyHeading = (size) => {
    const level = size === "normal" ? 0 : parseInt(size, 10);
    if (level === 0) {
      editor.chain().focus().setParagraph().run();
    } else {
      editor.chain().focus().toggleHeading({ level }).run();
    }
  };

  const addImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();

        const { path } = await uploadFileToS3(file);

        reader.onloadend = () => {
          editor.chain().focus().setImage({ src: path, alt: "Image" }).run();
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const addLink = () => {
    const url = prompt("URL of the link:");
    const text = prompt("Text for the link:") || "Link";

    if (url) {
      const isSelected = editor.state.selection.empty;

      if (isSelected) {
        editor
          .chain()
          .focus()
          .insertContent(
            `<a href="${url}" target="_blank" rel="noopener">${text}</a>`
          )
          .run();
      } else {
        editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: url })
          .run();
      }
    }
  };

  if (!editor) {
    return <p>Loading...</p>;
  }

  return (
    <Grid
      style={{
        marginLeft: -12,
      }}
    >
      <Row style={{ gap: 8, display: "flex", marginLeft: -8 }}>
        <select
          value={headingSize}
          onChange={(e) => setHeadingSize(e.target.value)}
        >
          <option value="normal">Normal</option>
          <option value="1">Heading 1</option>
          <option value="2">Heading 2</option>
          <option value="3">Heading 3</option>
          <option value="4">Heading 4</option>
          <option value="5">Heading 5</option>
          <option value="6">Heading 6</option>
        </select>
        <button
          style={{ marginRight: 12 }}
          onClick={() => applyHeading(headingSize)}
        >
          Apply
        </button>
        <button onClick={() => editor.chain().focus().toggleBold().run()}>
          Bold
        </button>
        <button onClick={() => editor.chain().focus().toggleItalic().run()}>
          Italic
        </button>
        <button onClick={addImage}>Add Image</button>
        <button onClick={addLink}>Add Link</button>
      </Row>

      <EditorContent editor={editor} className="editor-content" />

      <button
        style={{ marginTop: "12px", width: "120px", height: "36px" }}
        onClick={() => onSend(editor.getHTML(), resetEditor)}
      >
        Send
      </button>
    </Grid>
  );
};

export default function MessagesScreen() {
  const [selected, setSelected] = useState([]);
  const [languageGroup, setLanguageGroup] = useState("");

  const handleSend = (content, callback) => {
    const data = {
      content,
      userIds: selected.map((user) => user.id),
      languageGroup: languageGroup !== '' ? languageGroup : undefined,
    };

    post("/admin/user/message-multiple", data)
      .then(() => {
        alert("Message sent!");
        callback();
      })
      .catch(() => {
        alert("Error sending message.");
      });
  };

  const handleReset = () => {
    setSelected([]);
  };

  const handleUserCheck = (user) => {
    const selectedIds = selected.map((u) => u.id);
    const newSelected = selectedIds.includes(user.id)
      ? selected.filter((u) => u.id !== user.id)
      : [...selected, { id: user.id, pseudo: user.pseudo }];
    setSelected(newSelected);
  };

  const languageToLabel = {
    en: "English",
    es: "Spanish",
    fr: "French",
    de: "German",
    it: "Italian",
    pt: "Portuguese",
    nl: "Dutch",
    pl: "Polish",
    el: "Greek",
    ja: "Japanese",
    ca: "Catalan",
  };

  const getSubTitle = () => {
    const languageLabel =
      languageGroup !== ""
        ? `${languageToLabel[languageGroup]} speakers`
        : "No language group selected";
    const userSelectedLabel =
      selected.length > 0
        ? `Selected users: ${selected.map((u) => u.pseudo).join(", ")}`
        : "No users selected";

    return `${languageLabel} | ${userSelectedLabel}`;
  };

  return (
    <div className="main-content">
      <div className="editor-container">
        <Row>
          <Col md={12}>
            <Row style={{ margin: 12 }}>
              <select
                style={{ height: 26 }}
                value={languageGroup}
                onChange={(e) => setLanguageGroup(e.target.value)}
              >
                <option value="">No language group</option>
                <option value="en">English</option>
                <option value="es">Spanish</option>
                <option value="fr">French</option>
                <option value="de">German</option>
                <option value="it">Italian</option>
                <option value="pt">Portuguese</option>
                <option value="nl">Dutch</option>
                <option value="pl">Polish</option>
                <option value="el">Greek</option>
                <option value="ja">Japanese</option>
                <option value="ca">Catalan</option>
              </select>

              <button onClick={handleReset} style={{ marginLeft: 8 }}>
                Clear selected users
              </button>
            </Row>

            <Card
              title="Send a message"
              category={getSubTitle()}
              style={{ width: "100%" }}
              content={<Editor onSend={handleSend} onReset={handleReset} />}
            />
          </Col>
        </Row>
      </div>

      <UsersTable selected={selected} onUserCheck={handleUserCheck} />
    </div>
  );
}
