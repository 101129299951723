import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  getPremiumFreeCountries,
  addPremiumFreeCountry,
  removePremiumFreeCountry,
} from "services/subscriptions.service";

export default function PremiumFreeCountries() {
  const [premiumFreeCountries, setPremiumFreeCountries] = useState([]);
  const possibleCountries = [
    "AD",
    "AE",
    "AF",
    "AL",
    "AM",
    "AO",
    "AR",
    "AT",
    "AU",
    "AW",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BG",
    "BH",
    "BJ",
    "BL",
    "BN",
    "BO",
    "BQ",
    "BR",
    "BS",
    "BY",
    "BZ",
    "CA",
    "CD",
    "CG",
    "CH",
    "CI",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CU",
    "CV",
    "CW",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FR",
    "GA",
    "GB",
    "GE",
    "GF",
    "GG",
    "GH",
    "GI",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GT",
    "GU",
    "GY",
    "HK",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IQ",
    "IR",
    "IS",
    "IT",
    "JE",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LB",
    "LI",
    "LK",
    "LR",
    "LS",
    "LT",
    "LU",
    "LV",
    "LY",
    "MA",
    "MC",
    "MD",
    "ME",
    "MF",
    "MG",
    "MK",
    "ML",
    "MM",
    "MN",
    "MO",
    "MQ",
    "MT",
    "MU",
    "MV",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PK",
    "PL",
    "PM",
    "PR",
    "PS",
    "PT",
    "PY",
    "QA",
    "RE",
    "RO",
    "RS",
    "RU",
    "SA",
    "SC",
    "SD",
    "SE",
    "SG",
    "SI",
    "SK",
    "SL",
    "SM",
    "SN",
    "SR",
    "ST",
    "SV",
    "SX",
    "SY",
    "TG",
    "TH",
    "TN",
    "TR",
    "TT",
    "TW",
    "TZ",
    "UA",
    "UG",
    "US",
    "UY",
    "UZ",
    "VE",
    "VI",
    "VN",
    "VU",
    "XK",
    "YT",
    "ZA",
    "ZM",
    "ZW",
  ];

  useEffect(() => {
    getPremiumFreeCountries().then((countries) => {
      setPremiumFreeCountries(countries);
    });
  }, []);

  return (
    <>
      <div>
        {possibleCountries.map((country) => (
          <CountryButton
            isActive={premiumFreeCountries.includes(country)}
            onClick={() => {
              if (premiumFreeCountries.includes(country)) {
                removePremiumFreeCountry(country);
                setPremiumFreeCountries((c) => c.filter((x) => x !== country));
              } else {
                addPremiumFreeCountry(country);
                setPremiumFreeCountries((c) => [...c, country]);
              }
            }}
          >
            {country}
          </CountryButton>
        ))}
      </div>
    </>
  );
}

const CountryButton = styled.div`
  margin: 8px;
  background-color: ${(props) => (props.isActive ? "#bafb6d" : "white")};
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  border: 1px solid #444;
  border-radius: 4px;
`;
