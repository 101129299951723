/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";

import { get } from "services/utils.service";
import FeedRowComponent from "./Components/FeedRow";

class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
      events: [],
    };
  }
  componentDidMount() {
    this.fetchAdminEvents();
  }
  fetchAdminEvents = async () => {
    const events = await get("/admin/adminEvent");
    this.setState({
      events,
    });
  };
  render() {
    return (
      <div className="main-content">
        {this.state.events.map((el) => {
          return <FeedRowComponent el={el} />;
        })}
      </div>
    );
  }
}

export default Feed;
