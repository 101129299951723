import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import Select from "react-select";
import Button from "components/CustomButton/CustomButton.jsx";

export default function VideoComponent(props) {
  const { el: picture } = props;
  const [moderation, setModeration] = useState(null);

  const options = [
    {
      value: "nonSexual",
      label: "NON SEXUAL",
    },
    { value: "someSkin", label: "SOME SKIN" },
    { value: "softcore", label: "SOFTCORE" },
    { value: "hardcore", label: "HARDCORE" },
    { value: "illegal", label: "ILLEGAL" },
    { value: "unAuthorized", label: "UNAUTHORIZED" },
  ];

  const changeModerationHandler = (newModeration) => {
    setModeration(newModeration.value);
    props.onDone(
      {
        ...props.el,
        moderation: newModeration.value,
      },
      null
    );
  };

  useEffect(() => {
    if (props.el) {
      const initialModeration =
        picture.moderationAdmin !== "pending"
          ? picture.moderationAdmin
          : picture.moderationRating !== "pending"
          ? picture.moderationRating
          : picture.moderationHive;
      setModeration(initialModeration);
    }
  }, []);

  return (
    <Wrapper
      style={{
        border:
          props.el && props.el.deleteVideo
            ? "3px solid red"
            : "3px solid white",
      }}
    >
      <Video controls>
        <source src={props && props.el && props.el.path} type="video/mp4" />
      </Video>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          cursor: "pointer",
          padding: "2px 7px",
          backgroundColor: "#eee",
          borderRadius: 4,
          fontSize: 12,
        }}
      >
        {props.el.post ? "FEED" : props.el.album ? "ALBUM" : "-"}
      </div>
      <div
        style={{
          position: "absolute",
          top: 24,
          right: 0,
          cursor: "pointer",
          padding: "2px 7px",
          backgroundColor: "#eee",
          borderRadius: 4,
          fontSize: 12,
        }}
      >
        {props.el.moderationRating === "pending" ? "HIVE" : "COMMUNITY"}
      </div>
      <div style={{ height: 20 }}>
        {props.el.album && <span>{props.el.album.name}</span>}
      </div>
      <div style={{ padding: "15px", position: "relative", width: "100%" }}>
        <Select
          className="react-select primary"
          classNamePrefix="react-select"
          name="singleSelect"
          value={options.filter((option) => option.value === moderation)}
          //inputValue={props.moderation}
          onChange={(value) => changeModerationHandler(value)}
          defaultValue={{
            value: moderation,
          }}
          options={options}
          placeholder="Change moderation"
        />
        <Button
          bsStyle={props.el.deleteVideo ? "success" : "danger"}
          fill
          wd
          style={{ width: "100%", marginTop: 10 }}
          onClick={() => {
            let deletePic = props.el.deleteVideo ? false : true;
            console.log("deletePic", deletePic);
            props.onDone(props.el, null, deletePic);
          }}
        >
          {props.el.deleteVideo ? "Do not delete" : "Delete"}
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 450px;
  width: 350px;
  background-color: white;
  position: relative;
  border-radius: 4px;

  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  height: 50%;
  width: 100%;
  border-radius: 4px;
  object-fit: contain;
`;

const Video = styled.video`
  height: 50%;
  width: 100%;
  border-radius: 4px;
  object-fit: contain;
`;

const Modal = styled.div`
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  width: 500px;
  height: 300px;
`;

const Footer = styled.div`
  height: 41px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

/* ************************************ */

function CroperComponent(props) {
  const cropperRef = useRef(null);
  const [rotate, setRotate] = useState(0);
  const onCrop = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    console.log(cropper.getCroppedCanvas().toDataURL());
  };
  const onRotate = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    if (cropper) cropper.rotate(90);
  };

  const onSubmit = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    console.log(cropper.getCroppedCanvas().toDataURL());
    var file = convertUrlToFile(cropper.getCroppedCanvas().toDataURL());
    console.log("file", file);
    props.onDone(file);
  };
  return (
    <Modal>
      <ModalContent>
        <ImageWrapp>
          {props.src && (
            <Cropper
              src={props.src}
              style={{ height: 400, width: "100%" }}
              // Cropper.js options
              initialAspectRatio={10 / 16}
              viewMode={1}
              rotateTo={rotate}
              guides={true}
              ref={cropperRef}
              //checkCrossOrigin={false}
            />
          )}
        </ImageWrapp>
        <Footer>
          <IconButton onClick={onRotate}>
            <i className="fa fa-rotate-right" />
          </IconButton>
          <Done onClick={onSubmit}>Done</Done>
        </Footer>
      </ModalContent>
    </Modal>
  );
}

const ImageWrapp = styled.div`
  min-height: 200px;
  background-color: #828282;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const Done = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  background-color: white;
  padding: 10px 30px;
`;
