import React from "react";
import styled from "styled-components/macro";

export default function TextInput(props) {
  return <StyledInput {...props} />;
}

const StyledInput = styled.input`
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
`;
