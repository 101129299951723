import React, { useState, useEffect } from "react";
import { Alert, Button, FormControl } from "react-bootstrap";
import styled from "styled-components/macro";
import moment from "moment";
import Switch from "react-switch";
import Loader from "react-loader-spinner";

import CheckGuideModal from "../modals/check-guide.modal";
import DeleteGuideModal from "../modals/delete-guide.modal";
import Select from "../../../components/Form/select.input";

import {
  getGuide,
  getAdminGuides,
  moderateGuide,
} from "../../../services/guides.service";

export const mapApiTypeToLabel = {
  event: "Event",
  bar_night_club: "Bar / Night Club",
  restaurant: "Restaurant",
  shop: "Shop",
  association: "Association",
  accommodation: "Accommodation",
  art_culture: "Art / Culture",
};

const mapAdminCheckToLabel = {
  pending: "Pending",
  accepted: "Accepted",
  refused: "Refused",
};

export default function SelectGuides() {
  const [guides, setGuides] = useState([]);
  const [status, setStatus] = useState("loading"); // ENUM (idle | loading)
  const [displayedGuides, setDisplayedGuides] = useState([]);

  const [selectMode, setSelectMode] = useState("pending-only"); // ENUM (pending-only | all)
  const [pagesDisplayed, setPagesDisplayed] = useState(1);
  const [filterAdminCheck, setFilterAdminCheck] = useState("");
  const [filterInput, setFilterInput] = useState("");

  const [showGuideModal, setShowGuideModal] = useState(false);
  const [displayedGuide, setDisplayedGuide] = useState(null);

  const [showDeleteGuideModal, setShowDeleteGuideModal] = useState(false);
  const [guideToDelete, setGuideToDelete] = useState(null);

  const selectGuide = (guide) => {
    setDisplayedGuide(guide);
    setShowGuideModal(true);
  };

  const refreshDisplayedGuideData = () => {
    getGuide(displayedGuide.id).then((res) => {
      if (!res.error && !res.message) {
        setDisplayedGuide(res);

        setDisplayedGuides((prevGuides) => {
          const copiedGuides = JSON.parse(JSON.stringify(prevGuides));
          const guideUpdated = copiedGuides.find(
            (guide) => guide.id === displayedGuide.id
          );
          guideUpdated.title = res.title;
          return copiedGuides;
        });
      }
    });
  };

  const moderateGuideHandler = (guideId, adminCheck) => {
    moderateGuide({ guideId, adminCheck }).then((res) => {
      if (res?.success) {
        setGuides((prevGuides) =>
          prevGuides.filter((guide) => guide.id !== guideId)
        );
      }
    });
  };

  const refreshGuides = (mode) => {
    setGuides([]);
    setFilterAdminCheck("");
    setFilterInput("");
    setStatus("loading");
    getAdminGuides(mode).then((guides) => {
      if (Array.isArray(guides)) {
        setTimeout(() => {
          setStatus("idle");
          setGuides(guides);
        }, 500);
      } else {
        setStatus("idle");
      }
    });
  };

  const deleteGuideHandler = () => {
    setGuides((prevGuides) =>
      prevGuides.filter((guide) => guide.id !== guideToDelete.id)
    );
  };

  useEffect(() => {
    let filteredGuides = guides;

    if (!!filterAdminCheck) {
      filteredGuides = filteredGuides.filter(
        (guide) => guide.admin_check === filterAdminCheck
      );
    }

    filteredGuides = filteredGuides.filter(
      (guide) => new Date(guide.end_datetime) > new Date()
    );

    if (!!filterInput.trim()) {
      filteredGuides = filteredGuides.filter(
        (guide) =>
          guide.title.toLowerCase().includes(filterInput.toLowerCase()) ||
          guide.website.toLowerCase().includes(filterInput.toLowerCase())
      );
    }

    setDisplayedGuides(filteredGuides);
  }, [guides, filterAdminCheck, filterInput]);

  useEffect(() => {
    refreshGuides(selectMode);
  }, [selectMode]);

  return (
    <Wrapper>
      <Actions>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: 18, marginRight: 8 }}>Pending only</span>
          <Switch
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={40}
            disabled={status === "loading"}
            checked={selectMode === "all"}
            onChange={(checked) => {
              if (status === "idle") {
                if (checked) {
                  setSelectMode("all");
                } else {
                  setSelectMode("pending-only");
                }
              }
            }}
          />
          <span style={{ fontSize: 18, marginLeft: 8 }}>All</span>
        </div>
        {selectMode === "all" && (
          <div style={{ display: "flex", alignItems: "center", width: 400 }}>
            <span style={{ fontSize: 18, marginRight: 8 }}>Status</span>
            <Select
              value={filterAdminCheck}
              onChange={(e) => setFilterAdminCheck(e.target.value)}
            >
              <option value="">--</option>
              <option value="pending">Pending</option>
              <option value="refused">Refused</option>
              <option value="accepted">Accepted</option>
            </Select>
          </div>
        )}
        <div style={{ display: "flex", alignItems: "center", width: 400 }}>
          <span style={{ fontSize: 18, marginRight: 8 }}>Search</span>
          <FormControl
            type="text"
            name="search"
            placeholder="Title, website, ..."
            onChange={(e) => setFilterInput(e.target.value)}
            value={filterInput}
          />
        </div>
      </Actions>
      {displayedGuides.length > 0 ? (
        <table>
          <thead>
            <tr>
              <HeaderCell>Type</HeaderCell>
              <HeaderCell>Title</HeaderCell>
              <HeaderCell>Owner</HeaderCell>
              <HeaderCell>Creation date</HeaderCell>
              <HeaderCell>
                {selectMode === "all" && <span>Status</span>}
              </HeaderCell>
              <HeaderCell></HeaderCell>
            </tr>
          </thead>
          <tbody>
            {displayedGuides.slice(0, pagesDisplayed * 30).map((guide) => (
              <Row key={guide.id} onClick={() => selectGuide(guide)}>
                <Cell>
                  <Badge>{mapApiTypeToLabel[guide.type]}</Badge>
                </Cell>
                <Cell>{guide.title}</Cell>
                <Cell>
                  {guide.admin_created ? "Bearwww" : guide.owner.pseudo}
                </Cell>
                <Cell>{moment(guide.created_at).format("LLL")}</Cell>
                <Cell>
                  {selectMode === "pending-only" ? (
                    <Button
                      className="btn-fill btn-success"
                      onClick={(e) => {
                        e.stopPropagation();
                        moderateGuideHandler(guide.id, "accepted");
                      }}
                    >
                      Accept
                    </Button>
                  ) : (
                    <Badge>{mapAdminCheckToLabel[guide.admin_check]}</Badge>
                  )}
                </Cell>
                <Cell>
                  {selectMode === "pending-only" ? (
                    <Button
                      className="btn-fill btn-danger"
                      onClick={(e) => {
                        e.stopPropagation();
                        moderateGuideHandler(guide.id, "refused");
                      }}
                    >
                      Refuse
                    </Button>
                  ) : (
                    <Button
                      className="btn-fill btn-danger"
                      onClick={(e) => {
                        e.stopPropagation();
                        setGuideToDelete(guide);
                        setShowDeleteGuideModal(true);
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Cell>
              </Row>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          {status === "loading" && <Loader type="TailSpin" />}
          <CustomAlert
            className="alert-info"
            opacity={status === "loading" ? 0 : 1}
          >
            No guides available for this mode and filters.
          </CustomAlert>
        </>
      )}
      {guides.length > 30 * pagesDisplayed && (
        <span style={{ margin: "20px 0", width: 200 }}>
          <Button
            className="btn-fill btn-info"
            onClick={() => setPagesDisplayed((prevState) => prevState + 1)}
          >
            See more
          </Button>
        </span>
      )}
      <CheckGuideModal
        showModal={showGuideModal}
        closeModal={() => {
          setShowGuideModal(false);
          setDisplayedGuide(null);
        }}
        displayedGuide={displayedGuide}
        refreshDisplayedGuideData={refreshDisplayedGuideData}
      />
      <DeleteGuideModal
        showModal={showDeleteGuideModal}
        closeModal={() => {
          setShowDeleteGuideModal(false);
          setGuideToDelete(null);
        }}
        guideToDelete={guideToDelete}
        removeGuideToDelete={deleteGuideHandler}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 8px;
  }
`;

const Row = styled.tr`
  user-select: none;
  cursor: pointer;
  transition: all 200ms ease;
  height: 58px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const HeaderCell = styled.th`
  padding: 10px 5px;
`;

const Cell = styled.td`
  padding: 10px 5px;
`;

export const Badge = styled.span`
  padding: 6px 18px;
  background-color: #bbb;
  border-radius: 4px;
`;

export const CustomAlert = styled(Alert)`
  opacity: ${(p) => p.opacity || "0"};
  transition: opacity 400ms ease;
`;
