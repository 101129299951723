import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Alert, Button } from "react-bootstrap";

import { euCountries } from "constants/eu-countries-vat.constant";
import {
  getCurrentConstants,
  updateVatConstants,
} from "services/accounting.service";

export default function VatConfiguration() {
  const [constants, setConstants] = useState({});
  const [status, setStatus] = useState("idle"); // ENUM ('idle' | 'loading' | 'success' | 'error')

  const saveNewConstants = () => {
    if (status === "loading") {
      return;
    }

    const newConstants = {};
    for (const country of euCountries) {
      newConstants[country.value] = +constants[country.value] || 0;
    }

    setStatus("loading");
    updateVatConstants({ constants: newConstants }).then((res) => {
      if (res && res.success) {
        setStatus("success");
        setConstants(newConstants);
      } else {
        setStatus("error");
      }
    });
  };

  const onChangeVatValue = (e) => {
    const newValue = e.target.value;
    const euIsoCode = e.target.name;
    setConstants((prevState) => ({ ...prevState, [euIsoCode]: newValue }));
  };

  useEffect(() => {
    getCurrentConstants().then((res) => {
      if (!res.error) {
        setConstants(res);
      }
    });
  }, []);

  return (
    <div>
      <div>
        {euCountries.map((country) => (
          <FormRow>
            <span>{country.label}</span>
            <div>
              <input
                name={country.value}
                value={constants[country.value]}
                onChange={onChangeVatValue}
                type="number"
                min={0}
                max={100}
              />
              %
            </div>
          </FormRow>
        ))}
      </div>
      {status === "success" && (
        <Alert className="alert-info">This new configuration was saved.</Alert>
      )}
      {status === "error" && (
        <Alert className="alert-danger">
          Something went wrong while saving this configuration, please make sure
          every input is valid and try again.
        </Alert>
      )}
      <Button className="btn-fill btn-info" onClick={saveNewConstants}>
        Save
      </Button>
    </div>
  );
}

const FormRow = styled.label`
  display: flex;
  align-items: center;

  & span {
    font-size: 14px;
    color: #565656;
    width: 110px;
  }

  & input {
    width: 100px;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    color: #565656;
    padding: 8px 12px;
    height: 40px;
    box-shadow: none;
    font-size: 14px;
    margin-right: 5px;
  }
`;
