import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";

function App() {
  const [isLogged, setIslogged] = useState(false);
  useEffect(() => {
    let token = localStorage.getItem("tokenModeration");
    if (token) {
      setIslogged(true);
    }
  }, []);
  return (
    <div>
      <HashRouter>
        <Switch>
          {!isLogged && (
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          )}
          {isLogged && (
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
          )}
          <Redirect to={isLogged ? "/admin/dashboard" : "/auth/login-page"} />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
