import React, { useState, useEffect } from "react";
import PictureComponent from "../../Pictures/Components/Picture.component";
import styled from "styled-components/macro";
import moment from "moment";

const Wrapper = styled.div`
  border-bottom: 1px solid red;
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeedRowComponent = (props) => {
  return (
    <Wrapper>
      {props.el && props.el.picture.path && (
        <PictureComponent
          el={props.el.picture}
          onDone={() => alert("Work on progress")}
        />
      )}
      <Details>
        <div>Author: {props.el.owner.pseudo}</div>
        <div>
          Date: {moment(props.el.timestamp).format("DD-MM-YYYY, HH:mm")}
        </div>
      </Details>
    </Wrapper>
  );
};

export default FeedRowComponent;
