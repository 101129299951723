import { get, post, del, put } from "./utils.service";

export const createGuide = async (body) => {
  return await post("/guides", body);
};

export const getGuide = async (guideId) => {
  return await get(`/guides/${guideId}`);
};

export const getAdminGuides = async (mode) => {
  return await get(`/admin/guides?mode=${mode}`);
};

export const moderateGuide = async (body) => {
  return await post("/admin/guides/moderate", body);
};

export const deleteGuide = async (guideId) => {
  return await del(`/guides/${guideId}`);
};

export const updateGuide = async (guideId, body) => {
  return await put(`/guides/${guideId}`, body);
};
