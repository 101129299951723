import { get, post } from "./utils.service";

export const fetchPictures = async (moderation) => {
  return await get(`/admin/pictures?moderation=${moderation}`);
};

export const moderatePicture = async (body) => {
  return await post("/admin/picture/moderate", body);
};

export const bulkModeratePictures = async (body) => {
  return await post("/admin/picture/bulk-moderate", body);
};

export const deletePicture = async (body) => {
  return await post("/admin/picture/delete", body);
};
